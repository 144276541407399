/*
 * @Author: zhangzhipeng
 * @Date: 2021-10-13 14:35:17
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-08-25 08:15:13
 * @Description: 文件说明
 */
import { Mobx } from '@/store';
import global from '@/utils/global';
import { BussinessNo } from '@/utils/global/BussinessNo';
// import { toJS } from 'mobx';
import { get_query } from '@/utils/global/tools';
import React from 'react';

export async function afvfsToSinus(params: {
  afvfs: { ecgId: any; sindex: any; eindex: any }[];
  keyId?: any;
  bussi?: 'rep' | 'ecg';
}) {
  const bussinessNo = BussinessNo();
  const oper = `${bussinessNo}.MODIFY`;
  let headers = Mobx.EcgPublic.headers;
  if (!Mobx.EcgPublic.headers.oper) {
    headers = { oper };
  }
  const queryObj = get_query();
  return global.http.post(
    global.api.afvfsToSinus,
    {
      ...{
        bussi: params.bussi || Mobx.EcgPublic.moduleType,
        keyId: params.keyId || Mobx.EcgPublic.keyId || queryObj.keyId,
      },
      ...params,
    },
    {
      headers,
      alert: 'msg',
    },
  );
}
/**
 * 添加房扑
 * @returns
 */

export async function addAfVf(params: {
  afvfs: { ecgId: any; sindex: any; eindex: any }[];
  type: number;
  keyId?: any;
  bussi?: 'rep' | 'ecg';
  post_type?: 'put' | 'delete';
}) {
  let obj = global.http.put;
  if (params.post_type === 'delete') {
    obj = global.http.delete;
  }
  if (params.post_type === 'put') {
    obj = global.http.put;
  }
  const bussinessNo = BussinessNo();
  const oper = `${bussinessNo}.MODIFY`;
  let headers = Mobx.EcgPublic.headers;
  if (!Mobx.EcgPublic.headers.oper) {
    headers = { oper };
  }
  const queryObj = get_query();
  return obj(
    global.api.addAfVf,
    {
      ...{
        bussi: params.bussi || Mobx.EcgPublic.moduleType,
        keyId: params.keyId || Mobx.EcgPublic.keyId || queryObj.keyId,
      },
      ...params,
    },
    {
      headers,
      alert: 'msg',
    },
  );
}

/**
 * 添加特殊事件
 * @returns
 */

export async function addEvent(params: {
  events: { ecgId: any; sindex: any; eindex: any; etype: any }[];
  /**
   *是否留图
   *
   * @type {boolean}
   */
  toRep: boolean;
  note: string;
  keyId?: any;
  bussi?: 'rep' | 'ecg';
  type?: 'put' | 'delete';
}) {
  let obj = global.http.put;
  if (params.type === 'delete') {
    obj = global.http.delete;
  }
  if (params.type === 'put') {
    obj = global.http.put;
  }
  const bussinessNo = BussinessNo();
  const oper = `${bussinessNo}.MODIFY`;
  let headers = Mobx.EcgPublic.headers;
  if (!Mobx.EcgPublic.headers.oper) {
    headers = { oper };
  }
  const queryObj = get_query();
  return obj(
    global.api.addEvent,
    {
      ...{
        bussi: params.bussi || Mobx.EcgPublic.moduleType,
        keyId: params.keyId || Mobx.EcgPublic.keyId || queryObj.keyId,
      },
      ...params,
    },
    {
      headers,
      alert: 'msg',
    },
  );
}
/**
 * 自定义事件类型
 * @returns
 */
export async function loadManualEvents() {
  return global.http.post(global.api.loadManualEvents);
}
export async function insertAllSelBeats(params: any) {
  const bussinessNo = BussinessNo();
  const oper = `${bussinessNo}.MODIFY`;
  let headers = Mobx.EcgPublic.headers;
  if (!Mobx.EcgPublic.headers.oper) {
    headers = { oper };
  }
  const queryObj = get_query();
  return global.http.post(
    global.api.insertAllSelBeats,
    {
      ...{
        bussi: params.bussi || Mobx.EcgPublic.moduleType,
        keyId: params.keyId || Mobx.EcgPublic.keyId || queryObj.keyId,
      },
      ...params,
    },
    {
      headers,
    },
  );
}
/**
 * 修改/删除心搏
 * @param params
 * @returns
 */
export async function batchModifyAnno(params: {
  beats: {
    ecgId: any;
    bindex: any;
    btype?: any;
    bmatch?: any;
    rr?: any;
    nrr?: any;
    btypeName?: any;
    etypeName?: any;
  }[];
  btype?: any;
  /**
   * 1新加,2修改
   */
  oper?: 1 | 2;
  keyId?: any;
  bussi?: 'rep' | 'ecg';
  type?: 'put' | 'delete';
  /**
   * 心搏名称
   */
  name?: string;
  /**
   *心电特殊事件
   *
   * @type {number}
   */
  reqSrc?: number;
  batchIn?: boolean;
  /**
   *删除操作时，选择全部类型中的心搏进行删除时，不提示心搏类型
   *
   * @type {boolean}
   */
  flag?: boolean;
}) {
  let obj = global.http.put;
  if (params.type === 'delete') {
    obj = global.http.delete;
  }
  if (params.type === 'put') {
    obj = global.http.put;
  }
  const bussinessNo = BussinessNo();
  const oper = `${bussinessNo}.MODIFY`;
  let headers = Mobx.EcgPublic.headers;
  if (!Mobx.EcgPublic.headers.oper) {
    headers = { oper };
  }
  let confirmName: any = undefined;
  if (params.beats.length > 100 && params.type === 'delete') {
    confirmName = (
      <div>
        确定要删除已选择的<span style={{ fontSize: 18, color: 'blue' }}>{params.beats.length}</span>
        个
        {!params.flag ? (
          <span style={{ fontSize: 18, color: 'blue' }}>
            【{params.beats[0].btypeName || params.beats[0].etypeName}】
          </span>
        ) : null}
        心搏？
      </div>
    );
  }
  const queryObj = get_query();
  return obj(
    global.api.batchModifyAnno,
    {
      ...{
        bussi: params.bussi || Mobx.EcgPublic.moduleType,
        keyId: params.keyId || Mobx.EcgPublic.keyId || queryObj.keyId,
      },
      ...params,
    },
    {
      headers,
      alert: '',
      confirm: confirmName,
    },
  );
}

/**
 *  *     添加噪音
 * {"noises":[{"ecgId":13,"sindex":168865,"eindex":297589},{"ecgId":1355,"sindex":16886,"eindex":297589}]}
 * @param params
 * @returns
 */
export async function addNoise(params: {
  noises: { ecgId: any; sindex: any; eindex: any }[];
  keyId?: any;
  bussi?: 'rep' | 'ecg';
}) {
  const bussinessNo = BussinessNo();
  const oper = `${bussinessNo}.MODIFY`;
  let headers = Mobx.EcgPublic.headers;
  if (!Mobx.EcgPublic.headers.oper) {
    headers = { oper };
  }
  const queryObj = get_query();
  return global.http.put(
    global.api.addNoise,
    {
      ...{
        bussi: params.bussi || Mobx.EcgPublic.moduleType,
        keyId: params.keyId || Mobx.EcgPublic.keyId || queryObj.keyId,
      },
      ...params,
    },
    {
      headers,
    },
  );
}
export default { loadManualEvents, batchModifyAnno, addNoise, insertAllSelBeats };
