/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-04 13:37:17
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2021-09-28 10:19:48
 * @Description: 文件说明
 */
import DATA, { reductionPath } from '@/utils/global/data';
import routeDef from '@config/routes';
import { toJS } from 'mobx';
/**
 *
 * 获取BussinessNo
 * @export
 * @param {*} props
 * @return {*}  {*}
 */
export function BussinessNo(): any {
  const { location } = window;
  let pathname = location.pathname;
  if (location.hash !== '') {
    pathname = reductionPath(unescape(location.hash.replace('#', '')));
    pathname = pathname.replace('/admin/', '/admin**/');
  }
  let bussinessNo: number = 0;
  // @ts-ignore
  const routeDef_all: any = routeDef[0].routes![2].routes[0].routes[0].routes[1];
  const routeDef_all_arr = DATA.TreeToArray(routeDef_all.routes, {
    id: 'path',
    children: 'routes',
  });
  routeDef_all_arr.forEach((value2: any) => {
    if (`${value2.path}` === pathname) {
      bussinessNo = value2.bussinessNo;
    }
  });
  if (bussinessNo !== 0 && DATA.isNotNULL(bussinessNo)) {
    return bussinessNo;
  }
}
