/*
 * @Author: zhangzhipeng
 * @Date: 2021-03-17 16:57:07
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2021-03-23 13:36:24
 * @Description: loading页面
 */
import { PageLoading } from '@ant-design/pro-layout';

// loading components from code split
// https://umijs.org/plugin/umi-plugin-react.html#dynamicimport
export default PageLoading;
