export default {
  'menu.welcome': 'Selamat Datang',
  'menu.more-blocks': 'Blocks Lainnya',
  'menu.home': 'Halaman Awal',
  'menu.admin': 'Admin',
  'menu.admin.sub-page': 'Sub-Halaman',
  'menu.login': 'Masuk',
  'menu.register': 'Pendaftaran',
  'menu.register.result': 'Hasil Pendaftaran',
  'menu.dashboard': 'Dasbor',
  'menu.dashboard.analysis': 'Analisis',
  'menu.dashboard.monitor': 'Monitor',
  'menu.dashboard.workplace': 'Workplace',
  'menu.exception.403': '403',
  'menu.exception.404': '404',
  'menu.exception.500': '500',
  'menu.form': 'Form',
  'menu.form.basic-form': 'Form Dasar',
  'menu.form.step-form': 'Form Bertahap',
  'menu.form.step-form.info': 'Form Bertahap(menulis informasi yang dibagikan)',
  'menu.form.step-form.confirm': 'Form Bertahap(konfirmasi informasi yang dibagikan)',
  'menu.form.step-form.result': 'Form Bertahap(selesai)',
  'menu.form.advanced-form': 'Form Lanjutan',
  'menu.list': 'Daftar',
  'menu.list.table-list': 'Tabel Pencarian',
  'menu.list.basic-list': 'Daftar Dasar',
  'menu.list.card-list': 'Daftar Kartu',
  'menu.list.search-list': 'Daftar Pencarian',
  'menu.list.search-list.articles': 'Daftar Pencarian(artikel)',
  'menu.list.search-list.projects': 'Daftar Pencarian(projek)',
  'menu.list.search-list.applications': 'Daftar Pencarian(aplikasi)',
  'menu.profile': 'Profil',
  'menu.profile.basic': 'Profil Dasar',
  'menu.profile.advanced': 'Profile Lanjutan',
  'menu.result': 'Hasil',
  'menu.result.success': 'Sukses',
  'menu.result.fail': 'Gagal',
  'menu.exception': 'Pengecualian',
  'menu.exception.not-permission': '403',
  'menu.exception.not-find': '404',
  'menu.exception.server-error': '500',
  'menu.exception.trigger': 'Jalankan',
  'menu.account': 'Akun',
  'menu.account.center': 'Detail Akun',
  'menu.account.settings': 'Pengaturan Akun',
  'menu.account.trigger': 'Mengaktivasi Error',
  'menu.account.logout': 'Keluar',
  'menu.editor': 'Penyusun Grafis',
  'menu.editor.flow': 'Penyusun Alur',
  'menu.editor.mind': 'Penyusun Mind',
  'menu.editor.koni': 'Penyusun Koni',
};
