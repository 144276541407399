/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-04 13:37:17
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2023-04-24 16:25:57
 * @Description: 文件说明
 */
import { TreeToArray, isNotNULL } from '@/utils/global/data';
import JSEncrypt from 'node-rsa';
// import global from '@/utils/global';
import proxy from './proxy';
// import defaultMenus from '@config/menuData';
import type { LoginParams } from './type';
import { changMenu } from '@/utils/global/menus';
import { toJS } from 'mobx';
import defaultSettings from '@config/defaultSettings';
import { history } from 'umi';
import { transformRoute } from '@umijs/route-utils';
import { all } from '@config/proxy';
/**
 * @Description: 跳转首页
 * @return {*}
 */
export async function jump_to_homepage() {
  let res = true;
  await changMenu()
    .then((res3: any) => {
      console.log(res3);
      setTimeout(() => {
        const res3_arr = TreeToArray(toJS(res3), {
          id: 'path',
          children: 'routes',
        });
        const index_path = res3_arr.find((item, index) => {
          return item.name === defaultSettings.config.index.path;
        });
        if (index_path) {
          history.push(index_path.path);
        } else {
          const find = res3_arr.find((item, index) => {
            return item.name === 'user-area';
          });
          if (find) {
            history.push(find.path);
          } else {
            if (res3_arr[0].path) {
              history.push(res3_arr[0].path);
            }
          }
        }
      }, 200);
    })
    .catch((err) => {
      console.log(err);
      res = false;
      jump_to_login();
    });
  return res;
  // history.push({ pathname: "/test", state: { login_type:true } });
}
/**
 * @Description: 跳转登录
 * @return {*}
 */
export function jump_to_login() {
  let jump = '';
  if (window.location.origin.indexOf('//localhost') !== -1) {
    // @ts-ignore
    jump = `//${window.location.host}/api/${
      // @ts-ignore
      REACT_APP_ENV.split('@')[0]
    }#/login`;
  } else {
    let should_return = false;
    const keys = Object.keys(all);

    for (let index = 0; index < keys.length; index++) {
      const urls = all[keys[index]];
      for (let index2 = 0; index2 < urls.length; index2++) {
        let url = all[keys[index]][index2];
        url = url.indexOf(':') !== -1 ? url.split(':')[0] : url;
        // url = url.split(':')[0];
        if (window.location.origin.indexOf(url) !== -1 && !should_return) {
          console.log('跳转1', `//${window.location.host}/api/${keys[index]}#login`);
          should_return = true;
          // @ts-ignore
          jump = `//${window.location.host}/api/${keys[index]}#login`;

          break;
        }
      }
      if (should_return) break;
    }
    if (!should_return) {
      console.log('跳转2');
      /*if (window.location.pathname.indexOf('/api/') === -1) {
        console.log('走 /api/ === -1')
        // @ts-ignore
        jump = `//${window.location.host}/api/beta${window.location.hash}`;
      } else {
        console.log('不走 /api/ === -1')
        // @ts-ignore
        jump = ``;
      }*/
      jump = `//${window.location.host}/api/${
        // @ts-ignore
        REACT_APP_ENV.split('@')[0]
      }#/login`;
    }
  }
  if (REACT_APP_ENV && REACT_APP_ENV.split('@')[0] === 'zxapi' && jump !== '') {
    jump = `//${window.location.host}/api/zxapi#/login`;
  }
  if (window.location.hash === '#/login') {
    jump = ``;
  }
  console.log('login service jump===',toJS(jump))
  if (jump !== '') {
    window.location.href = jump;
  }

  return jump;
}
/**
 * 获取用户信息
 * @param
 */
export async function userInfo() {
  return proxy.userInfo();
}
/**
 * 登录
 * @param
 */

export async function login(params: LoginParams) {
  const key: any = await proxy.getKey();
  const end = await encryptValues(params.username, params.password, key.rdspk);
  const loginend: any = await proxy.login({
    username: end.username,
    password: end.password,
    extraInfo: 'browser:%E8%B0%B7%E6%AD%8C,bversion:70.0.3538.102,bwidth:1920,bheight:1080',
    channelVer: params.channelVer,
  });
  if (isNotNULL(loginend.data)) {
    return loginend;
  }
  return Promise.reject();
}

/**
 * 加密
 * @param username
 * @param password
 * @param key
 */
export async function encryptValues(username: string, password: string, key: string) {
  // console.log(key);
  try {
    const keyNew = new JSEncrypt(`-----BEGIN PUBLIC KEY-----${key}-----END PUBLIC KEY-----`);
    keyNew.setOptions({ encryptionScheme: 'pkcs1' }); // 就是新增这一行代码
    const enUsername = keyNew.encrypt(username, 'base64');
    const enPassword = keyNew.encrypt(password, 'base64');
    return {
      username: enUsername,
      password: enPassword,
    };
  } catch (error) {
    console.error('error', error);
    return {
      username: '',
      password: '',
    };
  }
}

export default { encryptValues, login, userInfo };
