/*
 * @Author: zhangzhipeng
 * @Date: 2021-08-16 08:11:32
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2021-11-26 13:36:51
 * @Description: 文件说明
 */
// 全局公用文件集合
import api from './api';
import http from './http';
import qs from 'qs';
import version from './version';
import * as DATA from './data';
import * as ecg from './ecg';
import * as tools from './tools';
import proxys from './proxys';

export { api, http, qs, version, DATA, ecg, tools, proxys };
export default {
  api,
  http,
  qs,
  version,
  DATA,
  ecg,
  tools,
  proxys,
};
