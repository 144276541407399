/*
 * @Author: zhangzhipeng
 * @Date: 2022-06-30 11:02:29
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-12-12 09:54:54
 * @FilePath: \xinguanjia_web_antdpro\src\utils\global\zoom.ts
 * @Description: 文件说明
 */

import { Mobx } from '@/store';
import defaultSettings from '@config/defaultSettings';
import { isPC } from './data';
/**
 * @Description: 小于该高度就缩放
 * @return {*}
 */
export const min_zoom_height = 960;
/**
 * @Description: 缩放高度基数
 * @return {*}
 */
export const def_zoom_height = 937;
/**
 * @Description: 带页签的高度
 * @return {*}
 */
export const zoom_h1920 = 937;
/**
 * @Description: 带页签的高度
 * @return {*}
 */
export const zoom_h1600 = 757;
/**
 * @Description: 带页签的高度
 * @return {*}
 */
export const zoom_h1366 = 617;
/**
 * @Description: 带页签的高度
 * @return {*}
 */
export const zoom_h1440 = 749;
/**
 * @Description: 不带页签的高度
 * @return {*}
 */
export const zoom_h_top_1920 = 967;
/**
 * @Description: 不带页签的高度
 * @return {*}
 */
export const zoom_h_top_1600 = 787;
/**
 * @Description: 不带页签的高度
 * @return {*}
 */
export const zoom_h_top_1366 = 655;
/**
 * @Description: 不带页签的高度
 * @return {*}
 */
export const zoom_h_top_1440 = 781;
/**
 * @Description: 宽度
 * @return {*}
 */
export const zoom_w1920 = 1920;
/**
 * @Description: 宽度
 * @return {*}
 */
export const zoom_w1600 = 1600;
/**
 * @Description: 宽度
 * @return {*}
 */
export const zoom_w1366 = 1366;
/**
 * @Description: 宽度
 * @return {*}
 */
export const zoom_w1440 = 1440;
/**
 * @Description: 最低宽高比
 * @return {*}
 */
export const min_w_h_ratio = 1250 / 723;
/**
 * @Description: 最小高度
 * @return {*}
 */
export const zoom_min_h = 617;
/**
 * @Description: 最小宽度
 * @return {*}
 */
export const zoom_min_w = 1360;

/**
 * @Description: 获取缩放等级
 * @return {*}
 */
export function get_rate() {
  // @ts-ignore
  const node = document.getElementById('root');
  // @ts-ignore
  // const node=document.body
  const should_zoom = localStorage.getItem('should_zoom');
  let rate = 1;

  if (!defaultSettings.config.zoom) {
    if (should_zoom === 'yes') {
      rate = document.body.clientHeight / def_zoom_height;
      if (document.body.clientWidth / document.body.clientHeight < min_w_h_ratio) {
        rate = document.body.clientWidth / zoom_w1920;
      }
    }
  } else {
    if (should_zoom !== 'no') {
      rate = document.body.clientHeight / def_zoom_height;
      if (document.body.clientWidth / document.body.clientHeight < min_w_h_ratio) {
        rate = document.body.clientWidth / zoom_w1920;
      }
    }
  }
  if (rate > 1) {
    rate = 1;
  }
  return rate;
}
/**
 * @Description: 设置缩放等级
 * @return {*}
 */
export function set_rate() {
  const should_zoom = localStorage.getItem('should_zoom');
  let rate = document.body.clientHeight / def_zoom_height;
  if (document.body.clientWidth / document.body.clientHeight < min_w_h_ratio) {
    rate = document.body.clientWidth / zoom_w1920;
  }
  if (rate > 1) {
    rate = 1;
  }
  if (!should_zoom && (defaultSettings.config.zoom || !isPC())) {
    localStorage.setItem('should_zoom', 'yes');
  }
  // TODO:pc正式环境不允许开启自适应
  if (isPC() && process.env.NODE_ENV !== 'development') {
    localStorage.setItem('should_zoom', 'no');
  }
  if (localStorage.getItem('should_zoom') === 'yes') {
    localStorage.setItem('rate', `${rate}`);
    const node = document.getElementById('root');
    // const node=document.body
    // @ts-ignore
    node.style.transform = 'scale(' + rate + ')';
    // @ts-ignore
    node.style.transformOrigin = 'top left';
    // @ts-ignore
    node.style.width = 100 / rate + '%';
    // @ts-ignore
    node.style.height = 100 / rate + '%';
    // @ts-ignore
    node.style['-moz-transform-origin'] = 'top left';
    if (document.body.clientHeight < zoom_h1600) {
      // @ts-ignore
      node.style.fontFamily = 'Microsoft YaHei';
      // @ts-ignore
      node.style.fontWeight = 'bold';
    }
  } else {
    localStorage.setItem('rate', `1`);
  }
}
/**
 * @Description: 字体是否是12
 * @return {*}
 */
export function is_font_size12() {

  if (Mobx.clientWidth <= zoom_w1600) {
    return true;
  } else {
    return false;
  }
}
