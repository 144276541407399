/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-04 13:37:17
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-08-24 08:22:09
 * @Description: 文件说明
 */
import { useEffect } from 'react';
import { Mobx } from '@/store';
import { DATA } from '@/utils/global';
import { history } from 'umi';
import defaultSettings from '@config/defaultSettings';
import { configure, toJS } from 'mobx';

configure({ enforceActions: 'never' });
/**
 *
 *store持久化。防止刷新掉数据
 * @export
 */

export function uselocalStorage(props: any) {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  // const { Mobx } = STORE();
  const { login } = Mobx;
  const { user } = Mobx;
  const { codeMap } = Mobx;
  const { postUnReadMessage } = Mobx;
  const { settings } = Mobx;
  const { IconFont } = Mobx;
  const { page_size_state } = Mobx;
  const list = ['user', 'login', 'codeMap', 'postUnReadMessage', 'settings', 'IconFont']; // 在此处设置需要缓存的store
  const listArr = [user, login, codeMap, postUnReadMessage, settings, IconFont]; // 在此处设置需要缓存的store
  /**
   * 生命周期
   */
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    const unloadEvent = (e: any) => {
      // 目前大部分浏览器已经移除了自定义关闭页面时的自定义提醒信息功能
      // 在页面刷新时将store里的信息保存到localStorage里
      console.log(window.location.hash);
      if (window.location.hash.indexOf('/login') !== -1) {
        for (let i = 0; i < list.length; i++) {
          localStorage.removeItem(window.location.hash + list[i]);
        }
      } else {
        for (let i = 0; i < list.length; i++) {
          localStorage.setItem(window.location.hash + list[i], JSON.stringify(Mobx[list[i]]));
        }
      }
      // const msg = '您确定要离开当前页面吗？';
      // e.returnValue = msg;
      // return msg;
    };

    // 在页面加载时时将localStorage存到store，然后清除localStorage
    setTimeout(() => {
      let has = true;
      for (let i = 0; i < list.length; i++) {
        if (!DATA.isNotNULL(localStorage.getItem(window.location.hash + list[i]))) {
          has = false;
          break;
        }
      }
      if (has && sessionStorage.getItem(window.location.hash + 'refresh') === 'yes') {
        if (sessionStorage.getItem(window.location.hash + 'UselocalStorageEnd') === 'yes') {
          try {
            for (let i = 0; i < list.length; i++) {
              //  后台强制掉登录之后,ide改代码这里会报undefined
              if (localStorage.getItem(window.location.hash + list[i])) {
                // @ts-ignore
                Mobx[list[i]] = JSON.parse(localStorage.getItem(window.location.hash + list[i]));
              }
            }
          } catch (error) {
            console.error('error', error);
            console.log(toJS(Mobx));
          }

          sessionStorage.setItem(window.location.hash + 'UselocalStorageEnd', 'no');
        }

        if (defaultSettings.config.logout === true) {
          for (let i = 0; i < list.length; i++) {
            localStorage.removeItem(window.location.hash + list[i]);
          }
          sessionStorage.removeItem(window.location.hash + 'UselocalStorageEnd');
          sessionStorage.removeItem(window.location.hash + 'refresh');
        }
      } else if (defaultSettings.config.logout === true) {
        for (let i = 0; i < list.length; i++) {
          localStorage.removeItem(window.location.hash + list[i]);
        }
        sessionStorage.removeItem(window.location.hash + 'UselocalStorageEnd');
        sessionStorage.removeItem(window.location.hash + 'refresh');
      }
    }, 0);
    // 在页面刷新时将vuex里的信息保存到localStorage里
    window.addEventListener('beforeunload', unloadEvent);
    return () => {
      // 相当于 componentWillUnmount
      window.removeEventListener('beforeunload', unloadEvent);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, listArr);
}
export function localStorageDef() {
  // 解决直接关闭页面，localStorage无法清除BUG

  sessionStorage.setItem(window.location.hash + 'refresh', 'yes');
  sessionStorage.setItem(window.location.hash + 'UselocalStorageEnd', 'yes'); // 死循环
}
