export default {
  'component.globalHeader.search': '検索',
  'component.globalHeader.search.example1': '検索例1',
  'component.globalHeader.search.example2': '検索例2',
  'component.globalHeader.search.example3': '検索例3',
  'component.globalHeader.help': 'ヘルプ',
  'component.globalHeader.notification': '通知',
  'component.globalHeader.notification.empty': 'すべての通知を表示しました。',
  'component.globalHeader.message': 'メッセージ',
  'component.globalHeader.message.empty': 'すべてのメッセージを表示しました。',
  'component.globalHeader.event': 'イベント',
  'component.globalHeader.event.empty': 'すべてのイベントを表示しました。',
  'component.noticeIcon.clear': 'クリア',
  'component.noticeIcon.cleared': 'クリア済み',
  'component.noticeIcon.empty': '通知なし',
  'component.noticeIcon.view-more': 'もっと見る',
};
