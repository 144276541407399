import { message, Modal } from 'antd';
import global from '@/utils/global';
import type { REFS } from '@/components/Table/type';
import { BeatData, FragEventData, Interval } from 'react-ecgchart';
import moment from 'moment';
import { DATA2_EcgChart3Pro } from '@/components/Ecg/EcgChart3Pro/type';
import { deepCopy, findEcgRawData, isNotNULL } from './data';
import { AbnEventTabEnum } from '@/pages/OpenWindow/Business/Ecg/EcgReportsEdit/type';
import Cookies from 'js-cookie';
import { batchModifyAnno } from '@/components/Ecg/EcgChart3Pro/proxy';
import { toJS } from 'mobx';
import Box from 'react-ecgchart/lib/ecg/model/box';
import { Mobx } from '@/store';
import { TYPES_ALL } from '@/components/Ecg/Abn/AbnType';
import { get_rate } from './zoom';

/**
 * 判断是否应该刷新ecg
 */
export function should_refresh_ecg() {
  // let find_noise = 0
  // for (let index2 = 0; index2 < props.chartProps.data.noiseData.length; index2++) {
  //   for (let index = 0; index < params.range.length; index++) {
  //     if (
  //       // 框选的选取开始点小于噪音结束点并且框选的结束点大于等于噪音的开始点
  //       (params.range[index].sindex <= props.chartProps.data.noiseData[index2].eindex && params.range[index].eindex >= props.chartProps.data.noiseData[index2].sindex)
  //     ) {
  //       find_noise = find_noise + 1
  //       break;
  //     }
  //   }
  // }
  // if(find_noise!==0 ){
  //   states.refresh = new Date().getTime();
  // }
}
/**
 * 由于个别浏览器对广告过滤或安全性做的比较严格，会认为window.open()打开的是小广告或病毒网站，所以就会将其拦截
 * 使用iframe标签在本页面打开下载，这样就绕过安全拦截
 * @param {string} url
 */
export function iframeDownload(url: string) {
  const iframe: any = document.createElement('iframe');
  iframe.style.display = 'none';
  function iframeLoad() {
    console.log('iframe onload');
    const win: any = iframe.contentWindow;
    // const doc = win.document;
    if (win.location.href === url) {
      iframe.parentNode.removeChild(iframe);
    }
  }
  if ('onload' in iframe) {
    iframe.onload = iframeLoad;
  } else if (iframe.attachEvent) {
    iframe.attachEvent('onload', iframeLoad);
  } else {
    // eslint-disable-next-line func-names
    iframe.onreadystatechange = function onreadystatechange() {
      if (iframe.readyState === 'complete') {
        iframeLoad();
      }
    };
  }
  iframe.src = '';
  document.body.appendChild(iframe);
  // eslint-disable-next-line func-names
  setTimeout(function loadUrl() {
    iframe.contentWindow.location.href = url;
  }, 50);
}

/**
 *
 *打印报告
 * @export
 * @param {*} record
 * @param {*} isShow
 * @param {*} refs
 */
export function printPDFReport(record: any, isShow: any, refs: REFS) {
  // 20201229:禅道bug#358:暂时的处理方案就是点击打印报告按钮，弹出选择报告打印项目弹窗，选择完成后提交后台以后后台进行判断是否填写了结论，
  // 这样不好的一点是不能前置判断，会导致用户操作的时候可能需要选择两遍

  // 20201230:禅道bug#358：一种方案就是点击的时候重新获取一遍列表，用报告id匹配到对应的报告，用请求下来的是否包含医生评论进行判断
  findRecordWithReloadList(record, isShow, refs);
}
/**
 *
 *打印报告
 * @export
 * @param {*} record
 * @param {*} isShow
 * @param {*} refs
 */
export function findRecordWithReloadList(record: any, isShow: any, refs: REFS) {
  const repNo = record.rep_no;
  const params = {
    businessNo: 2000022,
    page: 1,
    rows: 1,
    sort: 'report_date',
    order: 'desc',
    'filter.rep_no': repNo,
  };
  global.http
    .post(global.api.ecgReports, params)
    .then((res: any) => {
      const { data } = res;
      let conclusion = null;
      for (let i = 0; i < data.length; i += 1) {
        const report = data[i];
        if (report.rep_id === record.rep_id) {
          conclusion = report.doctor_conclusion;
          break;
        }
      }
      if (conclusion) {
        const data2 = {
          selectedRecord: record,
          isShow: record.rep_no.indexOf('MR') > -1,
        };
        refs.current.states.modalName = 'PrintPDF';
        refs.current.refsModal.current.states.visible = true;
        refs.current.refsModal.current.states.data = data2;
      } else {
        Modal.confirm({
          title: '该报告未填写医师结论，请先进行填写!',
          okText: '去填写',
          cancelText: '取消',
          onOk: () => {
            message.warning(`跳转`);
          },
          onCancel: () => {},
        });
      }
    })
    .catch((err) => {
      console.error('error', err);
      message.error('出现错误');
    });
}
/**
 *
 *打开pdf
 * @export
 * @param {({ pdf_download_addr: string | undefined })} record
 */
export function lookupPDFReport(record: { pdf_download_addr: string | undefined,pdf_path?: string |undefined }) {
  if (record.pdf_download_addr) {
    window.open(record.pdf_download_addr, 'pdf');
  }else if(record.pdf_path){
    window.open(record.pdf_path, 'pdfPath');
  } else {
    message.info('还未生成 PDF 报告');
  }
}
/**
 *
 *？？？？
 * @export
 * @param {number} mins
 * @return {*}
 */
export function getDurationByMin(mins: number): any {
  const result = [];
  if (mins > 0) {
    const duration = mins;
    let h = 0;
    let m = 0;
    let left = duration;
    h = Math.floor(left / 60);
    left -= 60 * h;
    m = left;
    if (h) result.push(`${h}小时`);
    if (m) result.push(`${m}分钟`);
  }
  return result.join('');
}
/**
 *
 *？？？？
 * @export
 * @param {number} seconds
 * @return {*}  {*}
 */
export function getDuration(seconds: number): any {
  const result = [];
  if (seconds > 0) {
    const duration = seconds;
    // let d = 0, h = 0, m = 0,  s = 0;
    let h = 0;
    let m = 0;
    let s = 0;
    let left = duration;
    // d = Math.floor(duration / (24 * 60 * 60));
    // left = duration - 24 * 60 * 60 * d;
    h = Math.floor(left / (60 * 60));
    left -= 60 * 60 * h;
    m = Math.floor(left / 60);
    left -= 60 * m;
    s = Math.floor(left);
    // if (d) result.push(`${d}天`)
    if (h) result.push(`${h}小时`);
    if (m) result.push(`${m}分钟`);
    if (s) result.push(`${s}秒`);
  }
  return result.join('');
}
/**
 *
 *？？？？
 * @export
 * @param {number} seconds
 * @return {*}
 */
export function getReportListDuration(seconds: number): any {
  let result = null;
  let hour_total = 0;
  let min = 0;
  if (seconds > 60) {
    const min_total = Math.floor(seconds / 60); // 分钟
    if (min_total < 60) {
      hour_total = 0;
      min = min_total;
    } else {
      hour_total = Math.floor(min_total / 60); // 小时数
      min = Math.floor(min_total % 60); // 余分钟
    }
    result = { hour: `${hour_total}小时`, minute: `${min}分钟` };
  } else {
    result = { hour: `0小时`, minute: `1分钟` };
  }
  return result;
}
/**
 * 获取一个心搏类型
 * @param baseCodeMap
 * @param beat
 * @returns
 */
export function get_one_ecg_name(beat: number) {
  const baseCodeMap = Mobx.codeMap.CD201;
  let res = '';
  try {
    if (!baseCodeMap || !baseCodeMap.length || !beat) return res;

    for (let j = 0; j < baseCodeMap.length; j++) {
      const code = baseCodeMap[j];
      if (beat === code.value) {
        res = code.text;
        break;
      }
    }
    if (beat === 200) {
      res = '房扑/房颤';
    }
    if (beat === 202) {
      res = '室扑/室颤';
    }
    return res;
  } catch (error) {
    return res;
  }
}
/**
 * @Description: 将心博标注名称和颜色
 * @param {any} baseCodeMap
 * @param {any} beats
 * @return {*}
 */
export function getAnnoTypeName(baseCodeMap: any, beats: any) {
  try {
    if (!baseCodeMap || !baseCodeMap.length || !beats || !beats.length) return [];
    for (let i = 0; i < beats.length; i++) {
      const item = beats[i];
      for (let j = 0; j < baseCodeMap.length; j++) {
        const code = baseCodeMap[j];
        const find = TYPES_ALL.find((d) => d.value === code.text);
        if (item.btype === code.value) {
          item.btypeName = code.text;
          item.btypeCode = code.code_string;
          item.color = find ? find.color || 'red' : 'red';
          break;
        }
        if (item.etype === code.value) {
          item.etypeName = code.text;
          item.etypeCode = code.code_string;
          item.color = find ? find.color || 'red' : 'red';
          break;
        }
      }
    }
    beats = beats.map((item: any) => {
      if (item.btype === 200) {
        const find = TYPES_ALL.find((d) => d.value === '房扑/房颤');
        item.btypeCode = 'Af';
        item.btypeName = '房扑/房颤';
        item.color = find ? find.color || 'red' : 'red';
      }
      if (item.btype === 202) {
        const find = TYPES_ALL.find((d) => d.value === '室扑/室颤');
        item.btypeCode = 'Vf';
        item.btypeName = '室扑/室颤';
        item.color = find ? find.color || 'red' : 'red';
      }
      return item;
    });
    return beats;
  } catch (error) {
    return beats;
  }
}
/**
 * @Description: 计算噪音开始时间标记
 * @param {any} dominateNumber
 * @param {number} queryStartTime
 * @return {*}
 */
export function noise_starting_time(dominateNumber: any, queryStartTime: number) {
  //添加点标记
  const pointStarts = [];
  const markerData: any[] = [];
  let pointStart = queryStartTime;
  pointStarts.push(pointStart);
  markerData.push({
    lineColor: '#0400ff91',
    dashLineSegments: [1, 12],
    timeStamp: pointStart,
    textStyle: {
      lineHeight: 1,
      background: '#f6f9fb',
      boxShadow:
        '0 1px 5px rgb(0 0 0 / 10%), 0 2px 7px rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 4%)',
      transformOrigin: 0,
      transform: 'scale(0.8)',
    },
    timeFormatter: 'HH:mm:ss',
    lineVisible: false,
    triangleSymbolVisible: true,
    textPosition: function (absoluteX: number, lineVisible: boolean, box: Box) {
      const textXOffset = absoluteX;
      const textYOffset = dominateNumber === undefined ? box.bottom - 15 : box.bottom - 15;
      return {
        x: textXOffset,
        y: textYOffset,
      };
    },
  });
  for (let i = 0; i < 3; i++) {
    let plus = 15001;
    if (dominateNumber) {
      plus = 20001;
    }
    pointStart += plus;
    pointStarts.push(pointStart);
    markerData.push({
      lineColor: '#0400ff91',
      dashLineSegments: [1, 12],
      timeStamp: pointStart,
      textStyle: {
        lineHeight: 1,
        background: '#f6f9fb',
        boxShadow:
          '0 1px 5px rgb(0 0 0 / 10%), 0 2px 7px rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 4%)',
        transformOrigin: 0,
        transform: 'scale(0.8)',
      },
      timeFormatter: 'HH:mm:ss',
      lineVisible: false,
      triangleSymbolVisible: true,
      textPosition: function (absoluteX: number, lineVisible: boolean, box: Box) {
        const textXOffset = absoluteX;
        const textYOffset = dominateNumber === undefined ? box.bottom - 15 : box.bottom - 15;
        return {
          x: textXOffset,
          y: textYOffset,
        };
      },
    });
  }
  return markerData;
}
/**
 * 噪音页面底部心电图数据
 * @param ecgData
 * @param ecgId
 * @param codeMap
 * @param queryStartTime
 * @returns
 */
export function getEcgDetailData(
  ecgData: {
    AFVFs: any[];
    beats: any[];
    edata: any[];
    estime: number;
    events: any[];
    noises: any[];
    fdatas: any;
    pmMarks: any[]
  } | null,

  codeMap: any,
  queryStartTime: any,
  ecgId: number,
  markStartTime?: any,
): DATA2_EcgChart3Pro {
  if (!ecgData || !codeMap)
    return {
      rampantData: [],
      starTimeStamp: queryStartTime,
      ecgStartTimestamp: queryStartTime,
      abnRTimestamp: queryStartTime + 60000 / 2,
      markerData: [],
      ecgData: [],
      beatData: [],
      noiseData: [],
      fragEventData: [],
      afvfData: [],
      ecgId,
      eindex: 0,
      sindex: 0,
      pacemakerData: []
    };

  let noiseData = ecgData.noises;
  noiseData = noiseData.map((item: any) => {
    item.min = item.start_time;
    item.max = item.end_time;
    item.fillColor = '#FFE800';
    return item;
  });
  const afvfData = ecgData.AFVFs;
  let disabledAffMode = true;
  let disabledVffMode = true;
  if (afvfData.length) {
    const findAff = afvfData.find((item: any) => item.type === 200);
    if (findAff) {
      disabledAffMode = false;
    }
    const findVff = afvfData.find((item: any) => item.type === 202);
    if (findVff) {
      disabledVffMode = false;
    }
  }
  const beats = ecgData.beats;
  // if (afvfData && afvfData.length && beats && beats.length) {
  //   // 房扑房颤和室扑室颤的显示更换逻辑：原来是需要在后台返回的AFVF列表才显示
  //   // 更换为:后台的beats中的btype是什么就显示什么
  //   beats = beats.map((item: { btype: number; btypeCode: string; btypeName: string }) => {
  //     if (item.btype === 200) {
  //       item.btypeCode = 'Af';
  //       item.btypeName = '房扑/房颤';
  //     }
  //     if (item.btype === 202) {
  //       item.btypeCode = 'Vf';
  //       item.btypeName = '室扑/室颤';
  //     }
  //     return item;
  //   });
  // }

  let beats2 = beats ? getAnnoTypeName(codeMap.CD201, beats) : [];

  // if (afvfData.length && beats2.length) {
  //   // 房扑房颤和室扑室颤的显示更换逻辑：原来是需要在后台返回的AFVF列表才显示
  //   // 更换为:后台的beats中的btype是什么就显示什么
  //   beats2 = beats2.map((item: any) => {
  //     if (item.btype === 200) {
  //       item.btypeCode = 'Af';
  //       item.btypeName = '房扑房颤';
  //     }
  //     if (item.btype === 202) {
  //       item.btypeCode = 'Vf';
  //       item.btypeName = '室扑室颤';
  //     }
  //     return item;
  //   });
  // }
  beats2 = !beats2
    ? beats2
    : beats2.map((item: any) => {
        let bpm = 0;
        if (item.rr && item.rr > 0) {
          bpm = Math.floor(60000 / item.rr);
        }
        const timestamp = ecgData.estime + item.bindex * 4;
        const typeName = item.btypeCode;
        const rr = item.rr;
        return {
          ...item,
          timestamp,
          type: typeName,
          rr,
          hr: bpm,
        };
      });
  // /////////////////
  // @ts-ignore
  const fragEventData: FragEventData[] = get_fragEventData(ecgData);
  let pacemakerData: any = []
  if(ecgData.pmMarks && ecgData.pmMarks.length){
    pacemakerData = ecgData.pmMarks.map((item: any)=> ecgData.estime + item * 4);
  }
  let ecgData2 = ecgData.edata;
  let dominateNumber: any = undefined;
  let multiLeadNames: any = undefined;
  if (ecgData.fdatas && ecgData.fdatas && ecgData.fdatas.datas && ecgData.fdatas.datas.length > 1) {
    ecgData2 = ecgData.fdatas.datas;
    dominateNumber = ecgData.fdatas.keyLead;
    multiLeadNames = ecgData.fdatas.multiLeadNames || undefined;
  }
  if (
    ecgData.fdatas &&
    ecgData.fdatas &&
    ecgData.fdatas.datas &&
    ecgData.fdatas.datas.length == 1
  ) {
    ecgData2 = ecgData.fdatas.datas[0];
    dominateNumber = undefined;
    multiLeadNames = undefined;
  }

  let markerData: any[] = noise_starting_time(dominateNumber, queryStartTime);

  if (markStartTime) {
    const find = markerData.find(
      (item: any) =>
        moment(item.timeStamp).format('YYYY-MM-DD HH:mm:ss') ===
        moment(Number(markStartTime)).format('YYYY-MM-DD HH:mm:ss'),
    );
    if (!find) {
      markerData.push({
        lineColor: '#0400ff91',
        timeStamp: Number(markStartTime),
        textStyle: {
          lineHeight: 1,
          background: '#f6f9fb',
          boxShadow:
            '0 1px 5px rgb(0 0 0 / 10%), 0 2px 7px rgb(0 0 0 / 12%), 0 3px 1px -2px rgb(0 0 0 / 4%)',
          transformOrigin: 0,
          transform: 'scale(0.8)',
        },
        timeFormatter: 'HH:mm:ss',
        triangleSymbolVisible: true,
        dashLineSegments: [1, 12],
        textPosition: function (absoluteX: number, lineVisible: boolean, box: Box) {
          const textXOffset = absoluteX + 10;
          const textYOffset = dominateNumber === undefined ? box.bottom - 15 : box.bottom - 15;
          return {
            x: textXOffset,
            y: textYOffset,
          };
        },
      });
    } else {
      find.lineVisible = true;
      find.textPosition = function (absoluteX: number, lineVisible: boolean, box: Box) {
        const textXOffset = absoluteX + 1;
        const textYOffset = dominateNumber === undefined ? box.bottom - 15 : box.bottom - 15;
        return {
          x: textXOffset,
          y: textYOffset,
        };
      };
    }
  }
  markerData = markerData.sort((a: any, b: any) => {
    return a.timeStamp - b.timeStamp;
  });

  return {
    markerData,
    dominateNumber,
    multiLeadNames,
    rampantData: [],
    starTimeStamp: queryStartTime,
    ecgStartTimestamp: queryStartTime,
    abnRTimestamp: queryStartTime + 60000 / 2,
    afvfData,
    ecgData: ecgData2,
    beatData: beats2,
    noiseData,
    fragEventData,
    ecgId,
    sindex: 0,
    eindex: 0,
    disabledAffMode,
    disabledVffMode,
    pacemakerData
  };
}
/**
 * 获取段事件
 */
export function get_fragEventData(ecgData: {
  AFVFs: any[];
  beats: any[];
  edata: any[];
  estime: number;
  events: any[];
  noises: any[];
}): FragEventData[] {
  return ecgData.events && ecgData.events.length
    ? ecgData.events.map((item: { start_time: number; end_time: number; etype: any }) => {
        let title = `${moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(
          item.end_time,
        ).format('YYYY-MM-DD HH:mm:ss')}`;
        const find = Mobx.codeMap.CD200.find((i: { value: any }) => i.value === item.etype);
        if (find) {
          title = `${find.text}:${moment(item.start_time).format('YYYY-MM-DD HH:mm:ss')} ~ ${moment(
            item.end_time,
          ).format('YYYY-MM-DD HH:mm:ss')}`;
        }

        return { min: item.start_time, max: item.end_time, title };
      })
    : [];
}

export function getSimpleEcgDetailData(props: any) {
  if (!props.ecgData || !props.ecgStartTimestamp || !props.codeMap || !props.currentPage) {
    return null;
  }
  const noiseData = props.ecgData.noises.map((item: any) => {
    const start_time = props.ecgStartTimestamp + item.sindex * 4;
    const end_time = props.ecgStartTimestamp + item.eindex * 4;
    return { ...item, start_time, end_time };
  });
  const fragData = props.ecgData.events.map((item: any) => {
    const start_time = props.ecgStartTimestamp + item.sindex * 4;
    const end_time = props.ecgStartTimestamp + item.eindex * 4;
    return { ...item, start_time, end_time };
  });

  let beats = getAnnoTypeName(props.codeMap.CD201, props.ecgData.beats);
  const afvfs = props.ecgData.AFVFs;
  if (afvfs.length && beats.length) {
    // 房扑房颤和室扑室颤的显示更换逻辑：原来是需要在后台返回的AFVF列表才显示
    // 更换为:后台的beats中的btype是什么就显示什么
    beats = beats.map((item: any) => {
      if (item.btype === 200) {
        item.btypeCode = 'Af';
        item.btypeName = '房扑/房颤';
      }
      if (item.btype === 202) {
        item.btypeCode = 'Vf';
        item.btypeName = '室扑/室颤';
      }
      return item;
    });
  }
  beats = beats.map((item: any) => {
    let bpm = 0;
    if (item.rr && item.rr > 0) {
      bpm = Math.floor(60000 / item.rr);
    }
    const timestamp = props.ecgData.estime + item.bindex * 4;
    const typeName = item.btypeCode;
    const rr = item.rr;
    return {
      timestamp,
      rr,
      type: typeName,
      hr: bpm,
    };
  });
  return { noiseData, fragData, annotationData: beats };
}

export const getStartTimeStampsWithEcgLists = (ecgLists: any[], pageIndex: number) => {
  if (!ecgLists || !ecgLists.length || pageIndex < 1)
    return { segmentStartTime: 0, ecgId: 0, targetStartTime: 0 };
  let segmentStartTime: number = 0;
  let ecgId: number = 0;
  let targetStartTime: number = 0;
  let totalPageSum: number = 0;
  for (let i = 0; i < ecgLists.length; i++) {
    const item = ecgLists[i];
    const segSum = Math.floor(item.ecg_length / 60);
    if (pageIndex <= segSum + totalPageSum) {
      segmentStartTime = item.segmentStartTimestamp;
      ecgId = item.ecg_id;
      const targetPageIndex = pageIndex - totalPageSum;
      targetStartTime = (targetPageIndex - 1) * 60 * 1000 + item.segmentStartTimestamp;
      break;
    } else {
      totalPageSum += segSum;
    }
  }
  return { segmentStartTime, ecgId, targetStartTime };
};

export const getDurationDayBySeconds = (seconds: any) => {
  const result = [];
  if (seconds > 0) {
    let left = seconds;
    const dd = Math.floor(left / (60 * 60 * 24));
    if (dd) {
      result.push(`${dd}天`);
      left = seconds - dd * (60 * 60 * 24);
    }
    const hh = Math.floor(left / (60 * 60));
    if (hh) {
      result.push(`${hh}小时`);
      left -= hh * 60 * 60;
    }
    const mm = Math.floor(left / 60);
    if (mm) {
      result.push(`${mm}分钟`);
    }
  }
  return result.join('');
};
/*
 * 长时程智能心电平台页面:
 * 因为后台没有将心律异常类型做过滤，所以需要本地过滤
 * 目前按照设计图给出的八种心律失常类型取数据
 * 八种类型是：'房颤/房扑','室性连律','室速','室上性连律', '室上速','心动过缓','心动过速','停搏'
 * */
// 长时程智能心电平台页面
export const getComprehensiveAbnNameWithCode = (abnCode: any) => {
  switch (abnCode) {
    case 212:
      return '停搏';
    case 218:
      return '心动过速';
    case 219:
      return '心动过缓';
    case 205:
      return '室上速';
    case 203:
      return '室上性联律';
    case 204:
      return '室上性联律';
    case 206:
      return '室速';
    case 201:
      return '室性联律';
    case 202:
      return '室性联律';
    case 200:
      return '房颤/房扑';
    default:
      return '';
  }
};

export const getDiffBySeconds = (seconds: number) => {
  if (seconds < 0 || !seconds) {
    return '';
  } else if (seconds < 60) {
    return seconds + '秒';
  } else {
    const min_total = Math.floor(seconds / 60); // 分钟
    const sec = Math.floor(seconds % 60); // 余秒
    if (min_total < 60) {
      return min_total + '分钟' + sec + '秒';
    } else {
      const hour_total = Math.floor(min_total / 60); // 小时数
      const min = Math.floor(min_total % 60); // 余分钟
      return hour_total + '小时' + min + '分钟' + sec + '秒';
    }
  }
};

export const getDurationBySeconds = (seconds: any) => {
  const result = [];
  if (seconds > 0) {
    const duration = seconds;
    let h = 0;
    let m = 0;
    let left = duration;
    h = Math.floor(left / (60 * 60));
    left -= 60 * 60 * h;
    m = Math.floor(left / 60);
    left -= 60 * m;
    if (h) result.push(`${h}小时`);
    if (m) result.push(`${m}分钟`);
  }
  return result.join('');
};
export const handleSliceAnnoList = (annoList: any[], timestamp: number) => {
  if (!annoList || !annoList.length) return [];
  const rPlotLines: BeatData[] = [];
  const start = timestamp;
  const end = start + 8000;
  for (let i = 0; i < annoList.length; i++) {
    const item = annoList[i];
    const find = TYPES_ALL.find((it: any) => it.code === item[1]);
    if (item[4] > start && item[4] <= end) {
      rPlotLines.push({
        timestamp: item[4],
        type: item[1],
        rr: item[2],
        hr: item[3],
        color:find ? find.color : 'red'
      });
    }
  }
  return rPlotLines;
};
export const handleAnnoList = (annoList: any[]) => {
  if (!annoList || !annoList.length) return [];
  const rPlotLines: BeatData[] = [];

  annoList.forEach((item: any) => {
    const find = TYPES_ALL.find((it: any) => it.code === item[1]);
    rPlotLines.push({
      timestamp: item[4],
      type: item[1],
      rr: item[2],
      hr: item[3],
      color: find ? find.color :'red'
    });
  });
  return rPlotLines;
};
export const getAnnoKeyMap = () => {
  return [
    {
      key: 'N',
      value: 'N',
      str: '正常',
      color: '#333',
      code: 'N',
    },
    {
      key: 'V',
      value: 'V',
      str: '室早',
      color: 'red',
      code: 'V',
    },
    {
      key: 'S',
      value: 'S',
      str: '室上早',
      color: 'purple',
      code: 'S',
    },
    {
      key: 'T',
      value: 'RonT',
      str: 'RonT',
      color: '#ff9900',
      code: 'RonT',
    },
    {
      key: 'X',
      value: 'X',
      str: '伪差',
      color: '#528B8B',
      code: 'X',
    },
    {
      key: 'Z',
      value: 'Z',
      str: '噪音',
      color: '#333',
      code: 'NOISE',
    },
    {
      key: 'D',
      value: 'D',
      str: '删除',
      color: '#333',
    },
    {
      key: 'Ve',
      value: 'Ve',
      str: '室性逸搏',
      code: 'Ve',
    },
    {
      key: 'Se',
      value: 'Se',
      str: '房性逸搏',
      code: 'Se',
    },
    {
      key: 'Je',
      value: 'Je',
      str: '交界性逸搏',
      code: 'Je',
    },
    {
      key: 'VP',
      value: 'VP',
      str: '心室起搏心搏',
      code: 'VP',
    },
    {
      key: 'AVP',
      value: 'AVP',
      str: '房室起搏心搏',
      code: 'AVP',
    },
    {
      key: 'AP',
      value: 'AP',
      str: '心房起搏心搏',
      code: 'AP',
    },
  ];
};
export const getScrollLeft = (rIndex: number, sIndex: number) => {
  return ((4 * (rIndex - sIndex)) / 1000 / 10) * 25 * 50 - 500;
};
export const getEcgDetailNoiseData = (noise: any) => {
  if (!noise || !noise.length) return [];
  return noise.map((item: any) => {
    const start_time = item.estime + item.sindex * 4;
    const end_time = item.estime + item.eindex * 4;
    return { ...item, start_time, end_time };
  });
};
export const getIndexByTimestamp = (ecgStartTime: number, timestamp: number) =>{
  if(!ecgStartTime || !timestamp) return -1;
  return Math.floor((timestamp - ecgStartTime)/ 4);
}
/**
 * @Description: 将点数转换为时间戳
 * @param {number} ecgStartTime
 * @param {number} index
 * @return {*}
 */
export const getTimestampByIndex = (ecgStartTime: number, index: number) => {
  return ecgStartTime + index * 4;
};
export const handleCopy = (text: string) => {
  const oInput = document.createElement('input');
  oInput.value = text;
  document.body.appendChild(oInput);
  oInput.select();
  document.execCommand('Copy');
  message.success('复制成功');
  oInput.remove();
};
/**
 * @description:将后台返回的心率趋势图数据处理成需要的格式
 * flag:代表是需要查看详情中的数据组合
 */
export const handleHrListData = (globalHrList: string | any[], flag?: any, estime?: number) => {
  if (!globalHrList || !globalHrList.length) return [];
  const hrList: { date: string; hrList: any; stime: any }[] = [];
  for (let i = 0; i < globalHrList.length; i++) {
    const hr = globalHrList[i];
    let nextHr = null;
    if (i + 1 < globalHrList.length) {
      nextHr = globalHrList[i + 1];
    }
    const date = moment(hr.stime).format('YYYY-MM-DD');
    const find = hrList.find((item) => item.date === date);
    if (find) {
      let hrData = [];
      if (flag) {
        hrData = hr.hrList.map(
          (item: {
            stime: number;
            avgBpm: any;
            minBpm: any;
            maxBpm: any;
            beatsNum: any;
            ecgId: any;
          }) => {
            return {
              ...item,
              estime,
            };
          },
        );
      } else {
        hrData = hr.hrList.map((item: { stime: number; avgBpm: any; ecgId: any }) => [
          item.stime * 1000,
          item.avgBpm,
          hr.stime,
          item.ecgId,
          moment(item.stime * 1000).format('YYYY-MM-DD HH:mm:ss'),
        ]);
        if (
          nextHr &&
          nextHr.hrList[0].stime * 1000 - hr.hrList[hr.hrList.length - 1].stime * 1000 > 60000
        ) {
          hrData.push([
            hr.hrList[hr.hrList.length - 1].stime * 1000 + 60000,
            null,
            null,
            null,
            null,
          ]);
        }
      }
      find.hrList = [...find.hrList, ...hrData];
    } else {
      let hrData = [];
      if (flag) {
        hrData = hr.hrList.map(
          (item: {
            stime: number;
            avgBpm: any;
            minBpm: any;
            maxBpm: any;
            beatsNum: any;
            ecgId: any;
          }) => {
            return {
              ...item,
              estime,
            };
          },
        );
      } else {
        hrData = hr.hrList.map((item: { stime: number; avgBpm: any; ecgId: any }) => [
          item.stime * 1000,
          item.avgBpm,
          hr.stime,
          item.ecgId,
          moment(item.stime * 1000).format('YYYY-MM-DD HH:mm:ss'),
        ]);
        if (
          nextHr &&
          nextHr.hrList[0].stime * 1000 - hr.hrList[hr.hrList.length - 1].stime * 1000 > 60000
        ) {
          hrData.push([
            hr.hrList[hr.hrList.length - 1].stime * 1000 + 60000,
            null,
            null,
            null,
            null,
          ]);
        }
      }
      const hrItem = {
        date,
        hrList: hrData,
        stime: hr.stime,
      };
      hrList.push(hrItem);
    }
  }
  return hrList;
};
export const getHrDataList = (data: any) => {
  if (!data.length) return [];
  const list: any[] = [];
  for (let i = 0; i < data.length; i++) {
    const item = data[i];
    item.hrList.map((it: any) => ({ ...it, stime: it.stime * 1000 }));
    const hrData: any[] = [];
    const date = moment(item.stime).format('YYYY-MM-DD');
    const find = list.find((it: any) => it.date === date);
    if (!find) {
      hrData.push(item.hrList);
      const hrItem = {
        date,
        hrList: hrData,
        stime: item.stime,
      };
      list.push(hrItem);
    } else {
      find.hrList.push(item.hrList);
    }
  }
  return list;
};
/**
 * @description:将时间戳转换成点数
 */
export const getAnnoIndex = (ecgStartTime: number, timestamp: number) => {
  if (!ecgStartTime || !timestamp) return null;
  return Math.floor((timestamp - ecgStartTime) / 4);
};
/**
 * @Description: 查找ecg ID,开始结束时间
 * @param {string} ecgList
 * @param {number} timestamp
 * @return {*}
 */
export const getEcgIdByTimestamp = (
  ecgList: string | any[],
  timestamp: number | undefined,
):
  | {
      ecgId: null;
      /**
       * @Description: ecg记录的开始时间
       * @return {*}
       */
      anaStartTimestamp: null;
      /**
       * @Description: ecg记录的结束时间
       * @return {*}
       */
      anaEndTimestamp?: undefined;
    }
  | {
      ecgId: any;
      anaStartTimestamp: any;
      anaEndTimestamp: any;
    } => {
  let ecgId = null;
  let anaStartTimestamp = null;
  let anaEndTimestamp = null;
  if ((!ecgList && !ecgList.length) || !timestamp) return { ecgId, anaStartTimestamp };
  for (let i = 0; i < ecgList.length; i++) {
    const item = ecgList[i];
    if (timestamp >= item.anaStartTimestamp && timestamp < item.anaEndTimestamp) {
      ecgId = item.ecg_id;
      anaStartTimestamp = item.anaStartTimestamp;
      anaEndTimestamp = item.anaEndTimestamp;
      break;
    }
  }
  return { ecgId, anaStartTimestamp, anaEndTimestamp };
};

export const formatEcgData = (ecgs: any) => {
  let ecgData = {
    AFVFs: [],
    beats: [],
    edata: [],
    events: [],
    noises: [],
    estime: 0,
    ecgId: 0,
    startTime: undefined,
    fdatas: undefined,
  };
  if (ecgs && ecgs.length) {
    for (let i = 0; i < ecgs.length; i++) {
      const ecg = ecgs[i];
      if (ecg.beats && ecg.beats.length) {
        ecg.beats = ecg.beats.map((item: { bindex: number }) => ({
          ...item,
          beatTime: ecg.estime + item.bindex * 4,
        }));
      }
      if (ecg.noises && ecg.noises.length) {
        ecg.noises = ecg.noises.map((item: { sindex: number; eindex: number }) => ({
          ...item,
          start_time: ecg.estime + item.sindex * 4,
          end_time: ecg.estime + item.eindex * 4,
        }));
      }
      if (ecg.AFVFs && ecg.AFVFs.length) {
        ecg.AFVFs = ecg.AFVFs.map((item: { sindex: number; eindex: number }) => ({
          ...item,
          start_time: ecg.estime + item.sindex * 4,
          end_time: ecg.estime + item.eindex * 4,
        }));
      }
      if (ecg.events && ecg.events.length) {
        ecg.events = ecg.events.map((item: { sindex: number; eindex: number }) => ({
          ...item,
          start_time: ecg.estime + item.sindex * 4,
          end_time: ecg.estime + item.eindex * 4,
        }));
      }
      ecgData = { ...ecgData, ...ecg };
    }
  } else {
    const ecg = ecgs;
    if (!ecg) return ecgData;
    if (ecg.beats && ecg.beats.length) {
      ecg.beats = ecg.beats.map((item: { bindex: number }) => ({
        ...item,
        beatTime: ecg.estime + item.bindex * 4,
      }));
    }
    if (ecg.noises && ecg.noises.length) {
      ecg.noises = ecg.noises.map((item: { sindex: number; eindex: number }) => ({
        ...item,
        start_time: ecg.estime + item.sindex * 4,
        end_time: ecg.estime + item.eindex * 4,
      }));
    }
    if (ecg.AFVFs && ecg.AFVFs.length) {
      ecg.AFVFs = ecg.AFVFs.map((item: { sindex: number; eindex: number }) => ({
        ...item,
        start_time: ecg.estime + item.sindex * 4,
        end_time: ecg.estime + item.eindex * 4,
      }));
    }
    if (ecg.events && ecg.events.length) {
      ecg.events = ecg.events.map((item: { sindex: number; eindex: number }) => ({
        ...item,
        start_time: ecg.estime + item.sindex * 4,
        end_time: ecg.estime + item.eindex * 4,
      }));
    }
    ecgData = { ...ecgData, ...ecg };
  }
  return ecgData;
};

export const getInvalidDataList = (hrData: string | any[]) => {
  if (!hrData || !hrData.length) return [];
  const invalidRange = [];
  for (let i = 0; i < hrData.length; i++) {
    if (i + 1 < hrData.length) {
      const current = hrData[i].hrList;
      const next = hrData[i + 1].hrList;
      const start = current[current.length - 1].stime * 1000 + 60000;
      const end = next[0].stime * 1000;
      invalidRange.push([start, end]);
    }
  }
  return invalidRange;
};

export const getRRRangeData = (rrData: string | any[]) => {
  if (!rrData || !rrData.length) return [];
  let rrRangeData: any[] = [];
  for (let i = 0; i < rrData.length; i++) {
    const rr = rrData[i];
    const rrs = rr.rrs;
    const estime = rr.estime;
    const rrList = [];
    if (rrs && rrs.length) {
      for (let j = 0; j < rrs.length; j++) {
        const timestamp = estime + rrs[j].bindex * 4;
        const rr2 = rrs[j].rr;
        rrList.push([
          timestamp,
          rr2,
          rrs[j].ecgId,
          moment(timestamp).format('YYYY-MM-DD HH:mm:ss'),
        ]);
      }
    } else {
      continue;
    }
    rrRangeData = [...rrRangeData, ...rrList];
  }
  return rrRangeData;
};
/*
 *
 *处理噪音处理、房扑房颤以及室扑室颤中的列表数据
 * */
export const handleListData = (list: string | any[]) => {
  if (!list || !list.length) return [];
  const listData: { date: string; list: any[] }[] = [];
  for (let i = 0; i < list.length; i++) {
    const listItem = list[i];
    const startDate = moment(listItem.estime + listItem.sindex * 4).format('YYYY-MM-DD');
    const start_time = listItem.estime + listItem.sindex * 4;
    const end_time = listItem.estime + listItem.eindex * 4;
    const find = listData.find((item) => item.date === startDate);
    const noiseData = {
      ...listItem,
      start_time,
      end_time,
      diff: (listItem.eindex - listItem.sindex) * 4,
      key: `${listItem.ecgId}-${listItem.sindex}-${listItem.eindex}`,
    };
    if (find) {
      find.list.push(noiseData);
    } else {
      const item = {
        date: startDate,
        list: [noiseData],
      };
      listData.push(item);
    }
  }
  return listData;
};
export function getInvalidHrData(hrData: string | any[]) {
  const invalidHrData = [];
  if (isNotNULL(hrData)) {
    for (let i = 0; i < hrData.length; i++) {
      const hr = hrData[i];
      const nextHr = hrData[i + 1];
      if (i + 1 < hrData.length) {
        if (nextHr[0] - hr[0] > 60000) {
          const item = {
            start: hr,
            end: nextHr,
          };
          invalidHrData.push(item);
        }
      }
    }
  }
  return invalidHrData;
}
/**
 * @Description:工具方法(心电特殊事件标签):获取心电特殊事件列表后，将每种类型的事件类型对应的缩略图描述放到Cookie中
 * @param abnList
 */
export function setCookiesAbnList(abnList: string | any[], tabPaneIndex: AbnEventTabEnum | number) {
  // 单发：室上性单发8  室性单发5
  // 逸搏：房性逸搏34  室性逸搏10
  // 起搏：心室起搏心搏125 房室起搏心搏126 心房起搏心搏128
  // RonT：41
  if (isNotNULL(abnList)) {
    for (let i = 0; i < abnList.length; i++) {
      const abn = abnList[i];
      if (tabPaneIndex === 2) {
        if (
          abn.id === -1 ||
          abn.id === 8 ||
          abn.id === 5 ||
          abn.id === 10 ||
          abn.id === 34 ||
          abn.id === 125 ||
          abn.id === 126 ||
          abn.id === 128 ||
          abn.id === 41
        ) {
          if (
            Cookies.get(`abnEType${abn.id}`) === undefined ||
            Cookies.get(`abnEType${abn.id}`) === 'undefined'
          ) {
            Cookies.set(`abnEType${abn.id}`, '2000');
          }
        } else {
          if (
            Cookies.get(`abnEType${abn.id}`) === undefined ||
            Cookies.get(`abnEType${abn.id}`) === 'undefined'
          ) {
            Cookies.set(`abnEType${abn.id}`, '4000');
          }
        }
      } else if (tabPaneIndex === 5) {
        if (
          Cookies.get(`abnSType${abn.id}`) === undefined ||
          Cookies.get(`abnSType${abn.id}`) === 'undefined'
        ) {
          Cookies.set(`abnSType${abn.id}`, '2000');
        }
      } else {
        if (
          Cookies.get(`abnBType${abn.id}`) === undefined ||
          Cookies.get(`abnBType${abn.id}`) === 'undefined'
        ) {
          Cookies.set(`abnBType${abn.id}`, '2000');
        }
      }
    }
  }
}
/**
 * 心搏编辑/特殊事件中修改或插入/删除心搏
 * @param params_modifyOrInsertYourHeart
 */
export async function modifyOrInsertYourHeart(params_modifyOrInsertYourHeart: {
  list: any;
  select_list: any[];
  btype?: string;
  /**
   * @Description: 1插入2修改
   * @return {*}
   */
  oper?: 1 | 2;
  type?: 'put' | 'delete';
  keyId?: any;
  bussi?: 'rep' | 'ecg' | undefined;
  /**
   *心电特殊事件
   *
   * @type {number}
   */
  reqSrc?: number;
  /**
   * 心搏名称
   */
  name: string | undefined;
  //删除超过100个心搏时，是否提示心搏名称（当前选择为全部类型时，不提示）
  flag?: boolean;
}) {
  let batchModifyAnno_beats: any[] = [];
  const { select_list, btype, oper, type, list, reqSrc } = params_modifyOrInsertYourHeart;
  batchModifyAnno_beats = findEcgRawData(toJS(list), select_list);
  const sameType = [];
  const differentType: any = [];
  batchModifyAnno_beats.forEach((item: any) => {
    if (Number(item.btype) === Number(btype) || Number(item.etype) === Number(btype)) {
      sameType.push(item);
    } else {
      differentType.push(item);
    }
  });
  if (
    sameType.length &&
    batchModifyAnno_beats.length &&
    sameType.length === batchModifyAnno_beats.length
  ) {
    message.warning('相同心搏类型无需修改');
    return null;
  }
  if (differentType.length) {
    batchModifyAnno_beats = differentType;
  }
  if (reqSrc === 2) {
    const beatsArray = [];
    for (let i = 0; i < batchModifyAnno_beats.length; i++) {
      const itemValue = batchModifyAnno_beats[i];
      if (itemValue.etype === 216 || itemValue.etype === 214) {
        beatsArray.push({
          ...itemValue,
          ecgId: itemValue.ecgId,
          btype: itemValue.etype,
          bindex: itemValue.sindex - 1,
        });
        beatsArray.push({
          ...itemValue,
          ecgId: itemValue.ecgId,
          btype: itemValue.etype,
          bindex: itemValue.eindex,
        });
      } else {
        beatsArray.push({
          ...itemValue,
          ecgId: itemValue.ecgId,
          btype: itemValue.etype,
          bindex: itemValue.cindex,
        });
      }
    }
    batchModifyAnno_beats = beatsArray;
  } else {
    batchModifyAnno_beats = batchModifyAnno_beats.map((value: any) => {
      let res_map = null;
      res_map = {
        ...value,
        ecgId: value.ecgId,
        btype: value.btype,
        bindex: value.bindex,
      };
      return res_map;
    });
  }
  const res = await batchModifyAnno({
    beats: batchModifyAnno_beats,
    btype,
    oper,
    type,
    reqSrc,
    flag: params_modifyOrInsertYourHeart.flag,
  });
  return res;
}
/**
 * 处理beats或噪音等接口返回的数据
 * @param res
 * @param params_new
 * @param left_width
 * @returns
 */
export function changeBeatsOrNoiseRes(
  res: any,
  params_new: any,
  left_width?: number,
  ecg_w?: number,
) {
  let list: any[] = [];
  let total = 0;
  const data = res.data.list ? res.data.list : [];
  total = res.data.total;
  list = changeBeatsOrNoiseList(data, params_new, left_width, ecg_w);

  return { total, list };
}
export const getAnnoTypeColorById = (abnTypeId: any) => {
  if (!abnTypeId) return 'rgb(89, 128, 195)';
  let color: any = 'rgb(89, 128, 195)';
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  const eventBTypeStr = getAnnoTypeById(Mobx.codeMap.CD201, abnTypeId);
  if(Number(abnTypeId) === 200){
    color = '#FF34B3';
  }
  const findEventType = TYPES_ALL.find((type) => type.code === eventBTypeStr);
  if (findEventType) {
    color = findEventType.color;
  }
  return color;
};

export const getAnnoTypeById = (baseCodeMap: any, abnTypeId: any) => {
  if (!baseCodeMap || !baseCodeMap.length || !abnTypeId) return null;
  let abnTypeStr = null;
  for (let j = 0; j < baseCodeMap.length; j++) {
    const code = baseCodeMap[j];
    if (abnTypeId === code.code_value) {
      abnTypeStr = code.code_string;
      break;
    }
  }
  return abnTypeStr;
};
export function formatBeats(beats: any, ecgId: any, annoList: any) {
  if (!annoList || !annoList.length) return beats;
  const annoListNew = annoList
    .filter((item: any) => item.ecgId === ecgId)
    .map((it: any) => {
      return {
        bindex: it.bindex,
        toType: it.toType,
      };
    });
  if (!annoListNew.length) return beats;
  let beatsArr = beats.split(',').map((item: any) => {
    const it = item.split('-');
    return {
      bindex: Number(it[0]),
      toType: Number(it[1]),
    };
  });
  for (let i = 0; i < annoListNew.length; i++) {
    const bindex = Number(annoListNew[i].bindex);
    const first = Number(beatsArr[0].bindex);
    const last = Number(beatsArr[beatsArr.length - 1].bindex);
    if (bindex >= first && bindex <= last) {
      const find = beatsArr.find((item: any) => Number(item.bindex) === Number(bindex));
      if (!find) {
        beatsArr.push(annoListNew[i]);
      }
    }
  }
  beatsArr = beatsArr
    .sort((a: any, b: any) => Number(a.bindex) - Number(b.bindex))
    .map((item: any) => {
      return `${item.bindex}-${item.toType}`;
    })
    .join(',');
  return beatsArr;
}
export function formatRampData(data: any, timestamp: any, annoList?: any[]) {
  const rampantData: any = [];
  if (data.beats) {
    const eventsListArr = data.beats.split(',');
    for (let i = 0; i < eventsListArr.length; i++) {
      const eventItemArr = eventsListArr[i].split('-');
      const eventBIndex = Number(eventItemArr[0]);
      let eventBType = Number(eventItemArr[1]);
      if (annoList && annoList.length) {
        const find = annoList.find(
          (item: any) => item.bindex === eventBIndex && item.ecgId === data.ecgId,
        );
        if (find) {
          eventBType = find.toType || 9999;
        }
      }
      const eventBTypeStr = getAnnoTypeById(Mobx.codeMap.CD201, eventBType);
      const findEventType = TYPES_ALL.find((type) => type.code === eventBTypeStr);
      const estime = data.estime || data.ecgStartTime;
      const pointStart = timestamp - (data.fdata.length / 250 / 2) * 1000 - estime;
      const pointStartIndex = Math.floor(pointStart / 4);
      const pointEndIndex = pointStartIndex + data.fdata.length;
      if (eventBIndex >= pointStartIndex && eventBIndex <= pointEndIndex && findEventType) {
        const start =
          eventBIndex - pointStartIndex - 50 > 0 ? eventBIndex - pointStartIndex - 50 : 0;
        const end =
          eventBIndex - pointStartIndex + 50 > data.fdata.length
            ? data.fdata.length - 1
            : eventBIndex - pointStartIndex + 50;
        if (start >= 0 && end < data.fdata.length) {
          const item: any = {
            start,
            end,
            color:
              findEventType?.color && findEventType?.color !== '#333'
                ? findEventType?.color
                : 'red',
          };
          rampantData.push(item);
        }
      }
    }
  }
  return rampantData;
}
/**
 * 处理beats接口或噪音等返回的list
 * @param data
 * @param params_new
 * @param left_width
 * @param ecg_w
 * @returns
 */
export function changeBeatsOrNoiseList(
  data: any,
  params_new: any,
  left_width?: number,
  ecg_w?: number,
) {
  let list: any[] = [];
  if (isNotNULL(data)) {
    // 噪音
    if (data[0].type && data[0].type === 1) {
      for (let i = 0; i < data.length; i += 1) {
        list.push({
          ...data[i],
        });
      }
      return list;
    }
    // 房扑房颤
    if (data[0].type && data[0].type === 200) {
      for (let i = 0; i < data.length; i += 1) {
        list.push({
          ...data[i],
        });
      }
      return list;
    }
    // 室扑室颤
    if (data[0].type && data[0].type === 202) {
      for (let i = 0; i < data.length; i += 1) {
        list.push({
          ...data[i],
        });
      }
      return list;
    }
    // 详情
    if (data[0].seq) {
      for (let i = 0; i < data.length; i += 1) {
        list.push({
          ...data[i],
        });
      }
      return list;
    }
  } else {
    return list;
  }
  // 心搏编辑/心电特殊事件
  for (let i = 0; i < data.length; i += 1) {
    // 给list心电图用的
    let abnRIndex = 0;
    if (data[i].bindex) {
      abnRIndex = data[i].bindex;
    } else if (data[i].cindex) {
      const row = data[i];
      if (
        row.etype === 5 ||
        row.etype === 8 ||
        row.etype === 10 ||
        row.etype === 11 ||
        row.etype === 34 ||
        row.etype === 41 ||
        row.etype === 125 ||
        row.etype === 126 ||
        row.etype === 128
      ) {
        // 室性单发、室上性单发、室性逸搏、交界性逸搏、房性逸搏、RonT、心室起搏、房室起搏、心房起搏
        abnRIndex = data[i].cindex;
      } else {
        abnRIndex = data[i].sindex;
      }
    }
    const ecgStartTimestamp = data[i].estime;
    const ecgStartTime = data[i].estime;
    const sindex = abnRIndex - 3750 < 0 ? 0 : abnRIndex - 3750;
    const scrollLeft = global.ecg.getScrollLeft(abnRIndex, sindex);
    const eindex = sindex + 7499;

    let timestamp = 0;

    if (data[i].bindex) {
      timestamp = data[i].estime + data[i].bindex * 4;
    } else if (data[i].cindex) {
      timestamp = data[i].estime + data[i].cindex * 4;
    }
    if (data[i].fdatas) {
      data[i].fdata = data[i].fdatas[0];
    } else if (!data[i].fdata) {
      data[i].fdata = [];
    }
    const startTimestamp = timestamp - (data[i].fdata.length / 250 / 2) * 1000;
    const find_type = TYPES_ALL.find((type) => type.value === get_one_ecg_name(data[i].btype));
    let rampantData = [];
    if ((data[i].sindex && data[i].eindex) || params_new.nbSecs) {
      rampantData = formatRampData(data[i], timestamp);
    } else {
      if (data[i].sttType) {
        rampantData = [];
      } else {
        let color = find_type?.color && find_type?.color !== '#333' ? find_type?.color : 'red';
        if (JSON.parse(localStorage.getItem('colorHeart') as string) === false) {
          color =
            find_type?.color && (find_type?.key === 'N' || find_type?.key === 'S')
              ? find_type?.color
              : 'red';
        }
        const pointStart = timestamp - (data[i].fdata.length / 250 / 2) * 1000 - data[i].estime;
        const pointIndex = Math.floor(pointStart / 4);
        const start = data[i].bindex - pointIndex - 50;
        const end = data[i].bindex - pointIndex + 50;
        const item: any = {
          start,
          end,
          color,
        };
        rampantData.push(item);
      }
    }

    // 底部心电图的
    /*
     * 长RR间期中，插入心搏时，是以事件的sindex作为中心点，往前取6秒，往后取6秒，为了不影响别的地方用。单独新增6个变量完成需求
     *
     */
    let eventCenterIndex = 0;
    let eventAbnStartIndex = 0;
    let eventAbnEndIndex = 0;
    let eventRTimestamp = 0;
    let eventStartTimestamp = 0;
    let eventScrollLeft = 0;
    let eventStartIndex = 0;
    let eventEndIndex = 0;
    if (data[i].etype === 212) {
      eventCenterIndex = data[i].sindex || 0;
      eventAbnStartIndex = eventCenterIndex - 1500 < 0 ? 0 : eventCenterIndex - 1500;
      eventAbnEndIndex =
        eventAbnStartIndex === 0 ? eventAbnStartIndex + 3000 : eventCenterIndex + 1500;
      eventRTimestamp = ecgStartTime + eventCenterIndex * 4;
      eventStartTimestamp = ecgStartTimestamp + eventAbnStartIndex * 4;
      eventScrollLeft = global.ecg.getScrollLeft(eventCenterIndex, eventAbnStartIndex);
      eventStartIndex = data[i].sindex || 0;
      eventEndIndex = data[i].eindex || 0;
    }
    const abnRTimestamp = ecgStartTime + abnRIndex * 4;
    let Abnsindex = abnRIndex - 1500 < 0 ? 0 : abnRIndex - 1500;
    let Abneindex = Abnsindex === 0 ? Abnsindex + 3000 : abnRIndex + 1500;
    // ////////////////////////////////////
    if (left_width !== undefined) {
      // 自适应屏幕长度start
      /**
       * ecg默认长度
       */
      const _y1920 = ecg_w ? ecg_w : 1681;
      /**
       * ecg默认长度
       */
      const _y1367 = 1099;
      /**
       * 每个点的宽度
       */
      const point_width =
        ~~(document.body.clientWidth / get_rate()) < 1367 ? _y1367 / 3000 : _y1920 / 3000;
      /**
       * ecg最大宽度
       */
      const ecg_width_max = ~~(document.body.clientWidth / get_rate()) - left_width - 8 - 16 - 100;
      /**
       * 可现实几个点
       */
      let point_num = parseInt(`${ecg_width_max / point_width}`);
      if (point_num % 50) {
        point_num = point_num - (point_num % 50);
      }
      if (Abneindex + point_num - 3000 > Abneindex) {
        Abnsindex =
          Abnsindex - parseInt(`${point_num / 2}`) + 1500 > 0
            ? Abnsindex - parseInt(`${point_num / 2}`) + 1500
            : 0;
        Abneindex = Abneindex + parseInt(`${point_num / 2}`) - 1500;
      }

      Abneindex = Abneindex - 1;
      if (eventAbnEndIndex + point_num - 3000 > eventAbnEndIndex) {
        eventAbnEndIndex = eventAbnEndIndex + point_num - 3000;
      }
      eventAbnEndIndex = eventAbnEndIndex - 1;
    }
    // 自适应屏幕长度end
    // ////////////////////////////////////
    const AbnstartTimestamp = ecgStartTimestamp + Abnsindex * 4;
    const AbnscrollLeft = global.ecg.getScrollLeft(abnRIndex, Abnsindex);

    let RR = '';
    if (isNotNULL(data[i].showValue) && data[i].showValue !== '') {
      RR = data[i].showValue;
    }
    if (isNotNULL(data[i].rr) && data[i].rr !== '') {
      RR = `RR:${data[i].rr}`;
    }
    let typeName = '';
    try {
      if (params_new && params_new.abn_types && params_new.abn_types.length) {
        if (data[i].btype) {
          const findType = params_new.abn_types.find(function (x: any) {
            return x.id === data[i].btype;
          });
          if (findType) {
            typeName = findType.value;
          }
        }
        if (data[i].etype) {
          const findType = params_new.abn_types.find(function (x: any) {
            return x.id === data[i].etype;
          });
          if (findType) {
            typeName = findType.value;
          }
        }
      }
    } catch (error) {
      console.error('error', error);
    }

    list.push({
      ...{
        ecgData: toJS(data[i].fdata),
        startTimestamp,
        date: data[i].estime + data[i].bindex * 4,
        id: abnRTimestamp,
        ecg_id: data[i].ecgId,
        rampantData: toJS(rampantData),
        eindex,
        sindex,
        ecgStartTimestamp: ecgStartTime,
        abnRTimestamp,
        scrollLeft,
        AbnstartTimestamp,
        Abnsindex,
        Abneindex,
        AbnscrollLeft,
        RR,
        typeName,
        eventCenterIndex,
        eventAbnStartIndex,
        eventAbnEndIndex,
        eventStartTimestamp,
        eventRTimestamp,
        eventScrollLeft,
        eventStartIndex,
        eventEndIndex,
      },
      ...data[i],
    });
  }
  list = getAnnoTypeName(Mobx.codeMap.CD201, list);
  return list;
}
export const getInvalidDataFlag = (ecgList: any, timestamp: any) => {
  if (!ecgList.length || !timestamp) return false;
  let find = false;
  for (let i = 0; i < ecgList.length; i++) {
    const ecg = ecgList[i];
    if (timestamp >= ecg.anaStartTimestamp && timestamp <= ecg.anaEndTimestamp) {
      find = true;
      break;
    }
  }
  return find;
};
/**
 * 是否跨ecg段
 */
export const isAccrossEcg = (ecgList: any, range: any[]) => {
  if (!ecgList.length || !range.length) return false;
  const start = range[0];
  const end = range[1];
  let across = false;
  for (let i = 0; i < ecgList.length; i++) {
    const ecg = ecgList[i];
    if (start >= ecg.anaStartTimestamp && end > ecg.anaEndTimestamp) {
      across = true;
      break;
    }
  }
  return across;
};
export const getInvalidRRRange = (rangeList: any) => {
  if (!rangeList.length) return [];
  const invalidRange = [];
  for (let i = 0; i < rangeList.length; i++) {
    const range = rangeList[i];
    if (i + 1 < rangeList.length) {
      const current = rangeList[i].rrs;
      const next = rangeList[i + 1].rrs;
      if (current && next) {
        const start = range.estime + current[current.length - 1].bindex * 4;
        const end = rangeList[i + 1].estime + next[0].bindex * 4;
        invalidRange.push([start, end]);
      }
    }
  }
  return invalidRange;
};
export const getRangeParams = (ecgList: any[], range: any[]) => {
  if (!ecgList.length || !range.length) return [];
  const startTime = range[0];
  const endTime = range[1];
  const ranges = [];
  for (let i = 0; i < ecgList.length; i++) {
    const ecg = ecgList[i];
    if (startTime >= ecg.anaStartTimestamp && endTime <= ecg.anaEndTimestamp) {
      //在ecg范围内
      const sindex = getAnnoIndex(ecg.anaStartTimestamp, startTime);
      const eindex = getAnnoIndex(ecg.anaStartTimestamp, endTime);
      if (sindex === 0 && eindex === 0) {
        continue;
      } else {
        const item = {
          ecgId: ecg.ecg_id,
          sindex,
          eindex,
        };
        ranges.push(item);
      }
    } else if (startTime < ecg.anaEndTimestamp && endTime > ecg.anaEndTimestamp) {
      const sindex =
        Number(getAnnoIndex(ecg.anaStartTimestamp, startTime)) < 0
          ? 0
          : getAnnoIndex(ecg.anaStartTimestamp, startTime);
      const eindex = getAnnoIndex(ecg.anaStartTimestamp, ecg.anaEndTimestamp);
      if (sindex === 0 && eindex === 0) {
        continue;
      } else {
        const item = {
          ecgId: ecg.ecg_id,
          sindex,
          eindex,
        };
        ranges.push(item);
      }
    } else if (endTime >= ecg.anaStartTimestamp && endTime <= ecg.anaEndTimestamp) {
      const sindex = getAnnoIndex(ecg.anaStartTimestamp, ecg.anaStartTimestamp);
      const eindex = getAnnoIndex(ecg.anaStartTimestamp, endTime);
      if (sindex === 0 && eindex === 0) {
        continue;
      } else {
        const item = {
          ecgId: ecg.ecg_id,
          sindex,
          eindex,
        };
        ranges.push(item);
      }
    } else if (startTime < ecg.anaStartTimestamp && endTime >= ecg.anaEndTimestamp) {
      const sindex = getAnnoIndex(ecg.anaStartTimestamp, startTime);
      const eindex = getAnnoIndex(ecg.anaStartTimestamp, ecg.anaEndTimestamp);
      if (sindex === 0 && eindex === 0) {
        continue;
      } else {
        const item = {
          ecgId: ecg.ecg_id,
          sindex,
          eindex,
        };
        ranges.push(item);
      }
    } else {
    }
  }
  return ranges;
};
export const getDataDurationFlag = (ecgList: any, selection: any) => {
  if (ecgList === undefined || !ecgList.length || !selection) return 1;
  const start = selection[0];
  const end = selection[1];
  let find = 0;
  for (let i = 0; i < ecgList.length; i++) {
    const ecg = ecgList[i];
    //if(start)
    if (start >= ecg.anaStartTimestamp && end <= ecg.anaEndTimestamp) {
      find = 1; //在有数据的段内，并且不跨段
      break;
    } else {
      const startFlag = getInvalidDataFlag(ecgList, start);
      const endFlag = getInvalidDataFlag(ecgList, end);
      if (!startFlag && !endFlag) {
        find = 0;
      } else {
        find = 2;
      }
    }
  }
  return find;
};
export const setSessionStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};
export const getSessionStorage = (key: string, pageSizeKey: string) => {
  const sessionItem = localStorage.getItem(key);
  if (sessionItem) {
    const pageSizeList = JSON.parse(sessionItem);
    return pageSizeList[pageSizeKey] || undefined;
  } else {
    return undefined;
  }
};

export const getAnnoTypeNameByType = (baseCodeMap: any, abnTypeId: any) => {
  if (!baseCodeMap || !baseCodeMap.length || !abnTypeId) return null;
  let abnTypeName = null;
  for (let j = 0; j < baseCodeMap.length; j++) {
    const code = baseCodeMap[j];
    if (abnTypeId === code.code_value) {
      abnTypeName = code.text;
      break;
    }
  }
  if (abnTypeId === 200) {
    abnTypeName = '房扑/房颤';
  } else if (abnTypeId === 202) {
    abnTypeName = '室扑/室颤';
  }

  return abnTypeName;
};
export const setSessionStorageItem = (key: string, pageSizeKey: any, value: any) => {
  const sessionItem = localStorage.getItem(key);
  if (sessionItem) {
    const pageSizeList = JSON.parse(sessionItem);
    pageSizeList[pageSizeKey] = value;
    setSessionStorage(key, pageSizeList);
  } else {
    setSessionStorage(key, { pageSizeKey: value });
  }
};
export default {
  setSessionStorage,
  getSessionStorage,
  setSessionStorageItem,
  getAnnoTypeNameByType,
  getAnnoTypeById,
  /**
   * @Description: 计算噪音开始时间标记
   * @param {any} dominateNumber
   * @param {number} queryStartTime
   * @return {*}
   */
  noise_starting_time,
  /**
   * 获取一个心搏类型
   * @param baseCodeMap
   * @param beat
   * @returns
   */
  get_one_ecg_name,
  getDurationByMin,
  getDiffBySeconds,
  getDuration,
  getReportListDuration,
  lookupPDFReport,
  printPDFReport,
  getSimpleEcgDetailData,
  getAnnoTypeName,
  handleAnnoList,
  handleSliceAnnoList,
  getDurationDayBySeconds,
  getComprehensiveAbnNameWithCode,
  getStartTimeStampsWithEcgLists,
  getDurationBySeconds,
  getAnnoKeyMap,
  getScrollLeft,
  getEcgDetailNoiseData,
  handleHrListData,
  getAnnoIndex,
  getEcgIdByTimestamp,
  formatEcgData,
  getInvalidDataList,
  getRRRangeData,
  handleListData,
  handleCopy,
  getEcgDetailData,
  getInvalidHrData,
  setCookiesAbnList,
  modifyOrInsertYourHeart,
  changeBeatsOrNoiseRes,
  changeBeatsOrNoiseList,
  getDataDurationFlag,
  formatRampData,
  formatBeats,
  getAnnoTypeColorById,
};
