/*
 * @Author: zhangzhipeng
 * @Date: 2022-08-23 08:26:27
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2023-03-14 09:20:22
 * @FilePath: \xinguanjia_web_antdpro\src\utils\global\data.ts
 * @Description: 文件说明
 */
import { AbnType, CDCD201_TYPE } from '@/components/Ecg/Abn/AbnType';
import { toJS } from 'mobx';
import { func } from 'prop-types';
/**
 * @Description: 判断是否为电脑
 * @return {*}
 */
export function isPC(): boolean {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  // const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  const isiOS = u.indexOf('iPhone') > -1 || u.indexOf('iPad') > -1; //ios终端
  /*
   * ipad环境判断更新
   * iOS pre 13 以前以ua作判断，13后以platform及maxTouchPoints做判断
   */
  const isiPad =
    navigator.userAgent.match(/(iPad)/) || (u.indexOf('Mac') > -1 && navigator.maxTouchPoints > 1);
  if (isAndroid || isiOS || isiPad) {
    return false;
  } else {
    return true;
  }
}
/**
 * @Description: 删除数组中的元素
 * @return {*}
 */
export function Array_remove(arr: any[], val: any) {
  const index = arr.indexOf(val);
  if (index > -1) {
    arr.splice(index, 1);
  }
}
/**
 * @Description: 删除数组中的元素,返回修改后的
 * @return {*}
 */
export function Array_remove_res(arr: any[], val: any) {
  const res = [];
  for (let i = 0; i < arr.length; i++) {
    if (JSON.stringify(arr[i]) !== JSON.stringify(val)) {
      res.push(arr[i]);
    }
  }
  return res;
}
/**
 * @Description: 判断是否为电脑
 * @return {*}
 */
export function size(): boolean {
  const u = navigator.userAgent;
  const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
  // const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
  const isiOS = u.indexOf('iPhone') > -1 || u.indexOf('iPad') > -1; //ios终端
  /*
   * ipad环境判断更新
   * iOS pre 13 以前以ua作判断，13后以platform及maxTouchPoints做判断
   */
  const isiPad =
    navigator.userAgent.match(/(iPad)/) || (u.indexOf('Mac') > -1 && navigator.maxTouchPoints > 1);
  if (isAndroid || isiOS || isiPad) {
    return false;
  } else {
    return true;
  }
}

//保留n位小数
export function roundFun(value: number, n: number): number {
  const string = `${value}`;
  const list = string.split('.');
  let string2 = string;
  if (isNotNULL(list) && list.length === 1) {
    string2 = list[0];
  } else {
    string2 = list[0] + '.' + list[1].slice(0, n);
  }
  return Number(string2);
}
/**
 * 时间戳转换为点数
 * @param params
 */
export function findBindex(params: { time: number; estime: any }) {
  const { time, estime } = params;
  let bindex: number = 0;
  bindex = (time - estime) / 4;
  return Math.round(bindex);
}

/**
 * 将底部一分钟心电图时间戳转换为点数
 * @param params
 */
export function findEindexAndSindex(params: { rpSelectTimePorts: any[]; estime: any; ecgId: any }) {
  const { rpSelectTimePorts, estime, ecgId } = params;
  console.log(22);
  console.log(rpSelectTimePorts);
  const addNoise_noises: {
    ecgId: any;
    sindex: any;
    eindex: any;
  }[] = [];
  // let offset = 248;
  // let data_length = 500;
  // if (params.offset) {
  //   offset = params.offset;
  // }
  // if (params.data_length) {
  //   data_length = params.data_length;
  // }
  for (let i = 0; i < rpSelectTimePorts.length; i++) {
    let sindex = 0;
    let eindex = 0;
    const min = rpSelectTimePorts![i].min;
    const max = rpSelectTimePorts![i].max;
    sindex = (min - estime) / 4;
    eindex = (max - estime) / 4;
    if (sindex < 0) {
      sindex = 0;
      eindex = 3000;
    }
    // sindex = (min - (data_length / 250 / 2) * 1000 - estime) / 4 + offset;
    // eindex = (max - (data_length / 250 / 2) * 1000 - estime) / 4 + offset;
    // sindex = (min - (click_data.fdata.length / 250 / 2) * 1000 - click_data.estime) / 4 + offset
    // eindex = (max - (click_data.fdata.length / 250 / 2) * 1000 - click_data.estime) / 4 + offset
    addNoise_noises.push({
      ecgId,
      sindex: Math.round(sindex),
      eindex: Math.round(eindex),
    });
  }
  return addNoise_noises;
}
/**
 *
 *通过abnRTimestamp/timestamp查找心搏原始数据
 * @export
 * @param {*} list
 * @param {any[]} select_list
 * @return {*}  {*}
 */
export function findEcgRawData(list: any, select_list: any[]): any {
  try {
    const keys = Object.keys(select_list);
    const res: any = [];

    // abnRTimestamp
    for (let i = 0; i < keys.length; i++) {
      const find_data: any = list.find((item: any) => {
        if (item.abnRTimestamp) {
          return item.abnRTimestamp === select_list[keys[i]];
        }
        return item.timestamp === select_list[keys[i]];
      });

      if (find_data) {
        res.push(find_data);
      }
    }
    return res;
  } catch (err) {
    // 在此处理错误
    console.error('error', err);
    return [];
  }
}
/**
 * 查找心搏类型CD201_TYPE
 * @returns
 */
export function findCD201_TYPE(obj: CDCD201_TYPE[], abnType: AbnType): CDCD201_TYPE {
  try {
    let confirmType: CDCD201_TYPE | undefined = toJS(obj).find((item: CDCD201_TYPE) => {
      return item.code_string === abnType.code;
    });
    if (abnType.key === 'Z_A') {
      confirmType = {
        code_string: 'NOISE',
        code_value: -1,
        text: '标记当前页为噪音',
        is_use: 1,
        value: -1,
      };
    }
    if (abnType.key === 'Z') {
      confirmType = { code_string: 'Z', code_value: -2, text: '噪音', is_use: 1, value: -2 };
    }
    if (abnType.key === 'D') {
      confirmType = { code_string: 'D', code_value: -3, text: '删除', is_use: 1, value: -3 };
    }
    if (abnType.key === 'X_D') {
      confirmType = {
        code_string: 'X_D',
        code_value: -4,
        text: '插入性单发',
        is_use: 1,
        value: -4,
      };
    }
    if (abnType.key === 'B_X') {
      confirmType = {
        code_string: 'B_X',
        code_value: -5,
        text: '标为伪差',
        is_use: 1,
        value: -5,
      };
    }
    return (
      confirmType! || {
        code_string: abnType.code,
        code_value: null,
        text: abnType.value,
        is_use: 1,
        value: null,
      }
    );
  } catch (err) {
    // 在此处理错误
    return { code_string: '0', code_value: 0, text: '0', is_use: 0, value: 0 };
  }
}
/**
 * 返回对象名称
 * @returns
 */
export function getObjName(obj: Record<string, any>, data: any) {
  try {
    const keys = Object.keys(obj);

    for (let i = 0; i < keys.length; i++) {
      if (data === obj[keys[i]]) {
        return keys[i];
      }
    }
  } catch (err) {
    // 在此处理错误
    return undefined;
  }
  return undefined;
}
/**
 * 返回环境变量
 * @returns
 */
export function ENV() {
  // const { WS } = process.env;
  // console.log('arguments: ' + process.argv[2]);
  try {
    if (window.location.hash !== '') {
      if (window.location.pathname.indexOf('/api/') !== -1) {
        // console.log(`${window.location.pathname.replace('/api/', '')}`);
        // @ts-ignore
        return `${window.location.pathname.replace('/api/', '')}`;
      }
      // console.log(``);
      return '';
    }
  } catch (err) {
    // console.log(`ecg`);
    // 在此处理错误
    return 'ecg';
  }
  // console.log(`${window.location.pathname.replace('/api/', '')}`);
  return '';
}
/**
 * 还原原始path
 * @param str
 * @returns
 */
export function reductionPath(str: any) {
  let str_new = str.replace(/\|.*\./, '|.');
  str_new = str_new.replace('.|', '');
  str_new = str_new.replace('.|', '');
  return str_new;
}
/**
 * binaryStr
 * @param 将字符串转换成二进制形式
 * @returns
 */
export function strToBinary(str: any) {
  const result = [];
  const list = str.split('');
  for (let i = 0; i < list.length; i++) {
    // if(i !== 0){
    //     result.push("");
    // }
    const item = list[i];
    const binaryStr = item.charCodeAt().toString(2);
    result.push(binaryStr);
  }
  return result.join('');
}
/**
 *
 *获取英文/中文的长度，英文长度为1，中文为2
 * @export
 * @param {string} str
 * @return {*}  {number}
 */
export function getLength(str: string): number {
  try {
    let n = 0;

    for (let i = 0; i < str.length; i += 1) {
      if (str.charCodeAt(i) >= 0 && str.charCodeAt(i) <= 256) {
        n += 1;
      } else {
        n += 2;
      }
    }
    return n;
  } catch (error) {
    return 0;
  }
}

/**
 *
 *删除数组中得到空值
 * @param {*} value
 * @return {*}
 */
export function delNullArray(array: any): any {
  for (let i = 0; i < array.length; i += 1) {
    if (array[i] == null || array[i] === '' || array[i] === undefined) {
      array.splice(i, 1);
      i -= 1;
    }
  }
  return array;
}

/**
 *
 *true:数值型的，false：非数值型
 * @param {*} value
 * @return {*}
 */
export function isNumber(num: any): any {
  return typeof num;
}
/**
 *判断空值
 *
 * @export
 * @param {(any|null|undefined)} d
 * @return {*}
 */
export function isNotNULL(d: any): any {
  try {
    // eslint-disable-next-line no-void
    if (d === undefined || d === null || d === '' || d === void 0) {
      return false;
    }
    if (typeof d === 'object') {
      if (d.length !== undefined) {
        if (d.length === 0) {
          return false;
        } else {
          return true;
        }
      } else {
        const keys = Object.keys(d);
        if (keys.length === undefined || keys.length === 0) {
          return false;
        } else {
          return true;
        }
      }
    }
  } catch (error) {
    return true;
  }
  return true;
}
/**
 *将undefined null的参数赋值为{}，以便赋值
 *
 * @export
 * @param {(any|null|undefined)} d
 * @return {*}
 */
export function noNULL(d: any | null | undefined): any {
  if (d === undefined || d === null) {
    return {};
  }
  return d;
}

/**
 * 根据提供的 id, pid 和 children 将一个个节点构建成一棵或者多棵树
 * id 数据里的 id，string 类型
 * pid 数据里的父 id，string 类型
 * children 生成结果中子节点的字段名，string 类型
 *
 * @param nodes 节点对象
 * @param config 配置对象
 */
export function arrayToTree(
  nodes: any,
  config?: {
    id?: string;
    pid?: string;
    children?: string;
  },
) {
  const id = (config && config.id) || 'id';
  const pid = (config && config.pid) || 'pid';
  const children = (config && config.children) || 'children';

  const idMap = {};

  const jsonTree: any = [];
  // eslint-disable-next-line @typescript-eslint/no-unused-expressions
  nodes.forEach((v: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    v && (idMap[v[id]] = v);
  });
  nodes.forEach((v: any) => {
    if (v) {
      const parent = idMap[v[pid]];
      if (parent) {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        !parent[children] && (parent[children] = []);
        parent[children].push(v);
      } else {
        jsonTree.push(v);
      }
    }
  });

  return jsonTree;
}

/**
 * 根据配置的 id, pid 和 children 把解构化的树型对象拆解为一个个节点
 * id 数据里的 id，string 类型，默认为 'id'
 * pid 数据里添加的父 id 信息，string 类型，默认为 'pid'
 * children 生成结果中子节点的字段名，string 类型，默认为 'children'
 * @param forest 单个或者多个树型对象
 * @param config 配置
 */
export function TreeToArray(
  forest: any,
  config?: {
    id?: string;
    pid?: string;
    children?: string;
  },
) {
  try {
    const id = (config && config.id) || 'id';
    const pid = (config && config.pid) || 'pid';
    const children = (config && config.children) || 'children';

    function flatTree(tree: any) {
      const queue = [tree];
      const result = [];
      while (queue.length) {
        let currentNode = queue.shift();
        if (currentNode.hasOwnProperty(id)) {
          if (!currentNode.hasOwnProperty(pid)) {
            currentNode = { ...currentNode, [pid]: null };
          }
          if (currentNode[children]) {
            // eslint-disable-next-line @typescript-eslint/no-unused-expressions
            currentNode[children].forEach((v: any) => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              v && queue.push({ ...v, [pid]: currentNode[id] });
            });
          }
          result.push(currentNode);
          delete currentNode[children];
        } else {
          throw new Error('you need to specify the [id] of the json tree');
        }
      }
      return result;
    }

    if (Array.isArray(forest)) {
      return forest.map((v) => flatTree(v)).reduce((pre, cur) => pre.concat(cur));
    }
    return flatTree(forest);
  } catch (error) {
    return [];
  }
}

/**
 * 处理权限列表
 */
export function chang_access_list(objPost: any) {
  const obj = JSON.parse(JSON.stringify(objPost)); // 重新赋值方法，防止影响老方法

  // eslint-disable-next-line no-restricted-syntax
  for (const key in obj) {
    // 有二级
    if (obj[key].children) {
      // eslint-disable-next-line no-restricted-syntax
      for (const key2 in obj[key].children) {
        // 有三级
        if (obj[key].children[key2].children) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key3 in obj[key].children[key2].children) {
            // 有四级
            // eslint-disable-next-line no-restricted-syntax
            // eslint-disable-next-line no-empty
            if (obj[key].children[key2].children[key3].children) {
            } else {
              // 判断是否有权限控件
              // eslint-disable-next-line no-restricted-syntax
              // eslint-disable-next-line no-lonely-if
              if (obj[key].children[key2].children[key3].access) {
                obj[key].children[key2].children[key3].children =
                  obj[key].children[key2].children[key3].access;
                delete obj[key].children[key2].children[key3].access;
              }
            }
          }
        } else {
          // 判断是否有权限控件
          // eslint-disable-next-line no-lonely-if
          if (obj[key].children[key2].access) {
            obj[key].children[key2].children = obj[key].children[key2].access;
            delete obj[key].children[key2].access;
          }
        }
      }
    } else {
      // 判断是否有权限控件
      // eslint-disable-next-line no-lonely-if
      if (obj[key].access) {
        obj[key].children = obj[key].access;
        delete obj[key].access;
      }
    }
  }
  // console.dir(obj);

  return obj;
}

/**
 * 获得符合条件的第N个数据
 * @param list
 * @param func
 * @param number
 * @returns
 */
function findArrayN(list: any[], func2: any, number?: number): any {
  if (number) {
    return list.filter(func2)[number];
  }
  return list.filter(func2);
}

/**
 * 在JS里，除Array和Object之外的数据类型的复制可以直接通过等号=来实现，
 * 但Array和Object类型的数据通过等号只是起引用作用，指向的是同一块内存地址。当源数据改变，引用的数据也同时会发生变化。
 * @param {*} obj
 * @param {Array<Object>} cache
 * @return {*}
 */

export function deepCopy(obj: any): any {
  try {
    if (!obj) return obj;
    // return JSON.parse(JSON.stringify(obj));
    let newobj = null; // 接受拷贝的新对象
    if (typeof obj == 'object' && typeof obj !== null) {
      // 判断是否是引用类型
      newobj = obj instanceof Array ? [] : {}; // 判断是数组还是对象
      for (const i in obj) {
        newobj[i] = deepCopy(obj[i]); // 判断下一级是否还是引用类型
      }
    } else {
      newobj = obj;
    }
    return newobj;
  } catch (error) {
    console.error('error', error);
    return obj;
  }
}
/**
 * 把类数组对象转换成一个真正的数组
 * {length:2,0:'first',1:'second'}=>["first","second"]
 * @param obj
 * @returns
 */

export function objectToArray(obj: any, index: number): any[] {
  const index2 = index || 0;
  return Array.prototype.slice.call(obj, index2);
}
/**
 * 合并数组并去重
 * @param arr1
 * @param arr2
 * @returns
 */
export function mergeArray(arr1: any[], arr2: any[]) {
  const _arr = new Array();
  for (let i = 0; i < arr1.length; i++) {
    _arr.push(arr1[i]);
  }
  for (let i = 0; i < arr2.length; i++) {
    let flag = true;
    for (let j = 0; j < arr1.length; j++) {
      if (arr2[i] === arr1[j]) {
        flag = false;
        break;
      }
    }
    if (flag) {
      _arr.push(arr2[i]);
    }
  }
  return _arr;
}
/**
 * @Description: 冒泡排序
 * @param {any} arr 原始数据
 * @param {*} order 排序
 * @param {string} columnKey 排序的key
 * @return {*}
 */
export function bubbleSort(arr: any[], order: 'ascend' | 'descend', columnKey: string) {
  for (let i = 0; i < arr.length - 1; i++) {
    //代表第几轮比较
    for (let j = 0; j < arr.length - 1 - i; j++) {
      //每一轮的两两相邻元素比较
      if (order === 'ascend') {
        if (arr[j][columnKey] > arr[j + 1][columnKey]) {
          //相邻元素比较
          [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]]; //满足条件，交换位置
        }
      } else if (order === 'descend') {
        if (arr[j][columnKey] < arr[j + 1][columnKey]) {
          //相邻元素比较
          [arr[j], arr[j + 1]] = [arr[j + 1], arr[j]]; //满足条件，交换位置
        }
      }
    }
  }
  return arr;
}
export default {
  isPC,
  bubbleSort,
  isNotNULL,
  noNULL,
  deepCopy,
  arrayToTree,
  TreeToArray,
  isNumber,
  delNullArray,
  getLength,
  objectToArray,
  mergeArray,
  findArrayN,
  strToBinary,
  reductionPath,
  getObjName,
  findEcgRawData,
  findEindexAndSindex,
  findBindex,
  roundFun,
  Array_remove_res,
  Array_remove,
};
