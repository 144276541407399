/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-04 13:37:17
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2023-01-04 08:54:43
 * @Description: 闭包实现
 */
// 全局数据存放处
import { createContext } from 'react';
import type { STATE as MOBXSTATE } from './mobx';
import type { STATE as SWRSTATE } from './swr';
import type { STATE as LOCALESTATE } from './locale';
import { _Mobx as Mobx2 } from './mobx';
import { swr as swr2 } from './swr';
import { locale as locale2 } from './locale';
// 在此声明接口类型

export type STATE = MOBXSTATE & SWRSTATE & LOCALESTATE;
// 父组件用的Context，数据全部存在Context
export const GlobalService = createContext<STATE>(null!);

export const useStoreService = (): STATE => {
  return {
    Mobx: Mobx2,
    swr: swr2,
    locale: locale2,
  };
};

/**
 * 全局数据存放处,类似redux，
 *  const { login } = STORE();
 */
export const STORE = useStoreService;
export const Mobx = STORE().Mobx;
export const Swr = STORE().swr;
export const Locale = STORE().locale;
