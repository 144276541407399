import { observable } from 'mobx';

/*
 * @Author: zhangzhipeng
 * @Date: 2021-07-08 08:51:27
 * @最后修改人: zhangzhipeng
 * @最后修改时间: 2022-04-25 13:25:06
 * @Description: swr缓存
 */
export type STATE = {
  swr: {
    no_end_list: Record<string, any>[];
    c_list: Record<string, any>;
  };
};
export const swr = observable({
  no_end_list: [],
  c_list: {},
});
export default swr;
