/*
 * @Author: zhangzhipeng
 * @Date: 2021-03-11 10:14:28
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2023-04-24 15:53:59
 * @Description: 文件说明
 */

import global, { DATA } from '../global';
// import defaultMenus from '@config/menuData';
import routeDef from '@config/routes';
import { Mobx } from '@/store';
import { toJS } from 'mobx';

// const { Mobx } = STORE();
export async function fechMenuData() {
  const res = await global.http.post(global.api.menuChildren);
  return res;
}
export async function fetchTopMenu() {
  const res = await global.http.post(global.api.menuParent);
  return res;
}

export async function changMenu() {
  return new Promise(async (resolve, reject) => {
    let routes_demo: any = [];
    let res_return: any = [];
    let res_routes_arr: any = [];
    let res_routes_arr_new: any = [];
    // @ts-ignore
    const routeDef_demo: any = routeDef[0].routes![2].routes[0].routes[0].routes[4];
    // @ts-ignore
    const routeDef_all: any = routeDef[0].routes![2].routes[0].routes[0].routes[1];
    const length = toJS(Mobx.menus).length;
    if (length === 0) {
      await fechMenuData()
        .then((res: any) => {
          if (res.tree) {
            const ids: any = [];
            res_routes_arr = DATA.TreeToArray(res.tree, {
              id: 'id',
              children: 'children',
            });
            const routeDef_all_arr = DATA.TreeToArray(routeDef_all.routes, {
              id: 'path',
              children: 'routes',
            });
            res_routes_arr = res_routes_arr.map((value: any) => {
              const return_res: any = {};
              //                 path: '/business/ecg',
              //                 name: 'ecg',
              //                 icon: 'FundViewOutlined',
              //                 bussinessNo: 2000011,
              //                 id: 345,
              if (value.bussinessNo === null || !value.bussinessNo) {
                return_res.path = `/admin.|${value.id}.|`;
                return_res.id = value.id;
                return_res.text = value.text;
                // return_res.key = value.id
                return_res.name = value.text;
                return_res.pid = value.pid;
                const find_icon = routeDef_all_arr.find((item: any) => {
                  if (item.icon === value.icon) {
                    return true;
                  }
                  if (item.iconCls === value.iconCls) {
                    return true;
                  }
                  return false;
                });
                if (find_icon && find_icon.icon) {
                  return_res.icon = find_icon.icon;
                }
              } else {
                routeDef_all_arr.forEach((value2: any) => {
                  if (`${value2.bussinessNo}` === value.bussinessNo) {
                    return_res.path = value2.path.replace('**', `.|${value.id}.|`);
                    return_res.name = value2.name;
                    return_res.text = value.text;
                    // return_res.key = value.id
                    return_res.id = value.id;
                    return_res.component = value2.component;
                    if (value2.icon) {
                      return_res.icon = value2.icon;
                    }
                    if (value2.locale) {
                      return_res.locale = value2.locale;
                    }
                    return_res.pid = value.pid;
                    return_res.bussinessNo = value.bussinessNo;
                  }
                });
              }

              return return_res;
            });
            // @ts-ignore
            routes_demo = routeDef_demo;

            res_routes_arr = DATA.delNullArray(res_routes_arr);
            res_routes_arr = DATA.arrayToTree(res_routes_arr, {
              id: 'id',
              children: 'routes',
            });

            for (let i = 0; i < res_routes_arr.length; i += 1) {
              if (res_routes_arr[i].routes) {
                res_routes_arr_new.push(res_routes_arr[i]);
              }
            }
            if (res_routes_arr.length !== 0 && res_routes_arr_new.length === 0) {
              res_routes_arr_new = res_routes_arr;
            }
            // if (process.env.NODE_ENV === 'development') {
            //   if (JSON.stringify(res_routes_arr_new) !== '[]') {
            //     res_return = [
            //       // @ts-ignore
            //       routes_demo,
            //       ...res_routes_arr_new,
            //     ];
            //   }
            // } else {
            if (JSON.stringify(res_routes_arr_new) !== '[]') {
              res_return = res_routes_arr_new;
            }
            // }

            if (JSON.stringify(res_routes_arr_new) === '[]') {
              reject();
            } else {
              Mobx.menus = res_return;
              resolve(res_return);
            }
          } else {
            // res_return= Promise.reject
            reject();
          }
        })
        .catch(() => {
          reject();
        });
    } else {
      resolve(Mobx.menus);
    }
  });
}
