// @ts-nocheck

import SmileOutlined from '@ant-design/icons/SmileOutlined';
import FundViewOutlined from '@ant-design/icons/FundViewOutlined';
import BankOutlined from '@ant-design/icons/BankOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';
import FundOutlined from '@ant-design/icons/FundOutlined';
import CommentOutlined from '@ant-design/icons/CommentOutlined';
import AreaChartOutlined from '@ant-design/icons/AreaChartOutlined'

export default {
  SmileOutlined,
FundViewOutlined,
BankOutlined,
SettingOutlined,
FundOutlined,
CommentOutlined,
AreaChartOutlined
}
    