import { L } from '@/utils/use/useLocale';
import { observable } from 'mobx';

/*
 * @Author: zhangzhipeng
 * @Date: 2021-07-08 08:51:27
 * @最后修改人: zhangzhipeng
 * @最后修改时间: 2022-04-25 13:25:06
 * @Description: locale缓存
 */
export type STATE = {
  /**
   * @Description: 国际化
   * @return {hooks: (key: L, values?: Record<string, string>) => string,class: (key: L, values?: Record<string, string>) => string;}
   */
  locale: {
    /**
     * @Description: hooks用的国际化
     * @return {*}
     */
    hooks: (key: L, values?: Record<string, string | number>) => string;
    /**
     * @Description: class用的国际化
     * @return {*}
     */
    class: (key: L, values?: Record<string, string | number>) => string;
  };
};
export const locale = observable({
  hooks: (key: L, values?: Record<string, string | number>) => '未翻译',
  class: (key: L, values?: Record<string, string | number>) => '未翻译',
});
export default locale;
