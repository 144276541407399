/*
 * @Author: zhangzhipeng
 * @Date: 2021-10-21 14:16:37
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-11-08 10:50:22
 * @Description: 文件说明
 */

import { AbnEventTabEnum } from '@/pages/OpenWindow/Business/Ecg/EcgReportsEdit/type';
import { message } from 'antd';
import Cookies from 'js-cookie';
import {
  IComputedValue,
  IObjectDidChange,
  IObservableValue,
  IValueDidChange,
  Lambda,
  ObservableMap,
  observe,
  set,
} from 'mobx';
import qs from 'qs';
import { useEffect } from 'react';

/**
 * 获取路由参数
 * @param disposes
 */
export function get_query() {
  let query = '';
  let queryobj: Record<string, any> = {};
  const str = window.location.hash;
  const index = str.indexOf('?');
  query = index ? str.substring(index + 1, str.length) : '';
  queryobj = qs.parse(`${query}`);
  if (queryobj?.repId) {
    queryobj.keyId = queryobj.repId;
    queryobj.moduleType = 'rep';
  }

  if (queryobj?.ecgId) {
    queryobj.keyId = queryobj.ecgId;
    queryobj.moduleType = 'ecg';
  }
  if (queryobj?.ecgId && queryobj.keyId) {
    queryobj.keyId = queryobj.keyId;
    if (queryobj.ecgId === queryobj.keyId) {
      queryobj.moduleType = 'ecg';
    } else {
      queryobj.moduleType = 'rep';
    }
  }
  if (queryobj?.token) {
    queryobj.keyId = queryobj.keyId;
    queryobj.moduleType = 'rep';
  }
  return queryobj;
}
/**
 * 卸载监听
 * @param disposes
 */
export function uninstall(disposes: { current: any }) {
  if (disposes.current.length !== 0) {
    for (let i = 0; i < disposes.current.length; i += 1) {
      if (disposes.current[i]) disposes.current[i]();
    }
  }
}
/**
 * @Description: 创建监听
 * @param {React} effect
 * @param {React} deps
 * @param {React} disposes
 * @return {*}
 */
export function useInstall(
  effect: React.EffectCallback,
  deps: React.DependencyList | undefined,
  disposes: React.MutableRefObject<any[]>,
) {
  /**
   * 生命周期
   */
  useEffect(() => {
    uninstall(disposes);
    effect();
    return () => {
      // 卸载
      uninstall(disposes);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
}
/**
 * 监听
 * @param disposes
 * @param object
 * @param listener
 * @param fireImmediately
 */
export function install(
  disposes: { current: any },
  object: Record<string, any>,
  listener: (change: IObjectDidChange) => void,
  fireImmediately?: boolean,
): Lambda {
  if (object) {
    const disposer_new = observe(object, listener, fireImmediately);
    disposes.current = [...disposes.current, disposer_new];
    return disposer_new;
  } else {
    return () => {};
  }
}
/**
 * 将state批量写入cookie
 * @param obj
 * @param values
 */
export function stateToCookies(obj: Record<string, any>, values: Record<string, any>) {
  //  await set(obj, values)
  obj = values;
  const keys = Object.keys(values);
  const { length } = keys;
  for (let i = 0; i < length; i += 1) {
    Cookies.set(keys[i], values[keys[i]]);
  }
}

export default {
  uninstall,
  stateToCookies,
  get_query,
};
