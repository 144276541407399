/*
 * @Author: zhangzhipeng
 * @Date: 2021-04-21 07:59:05
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-02-10 16:41:40
 * @Description: 轮询
 */

import global from '@/utils/global';
import { Mobx } from '@/store';

// const { Mobx } = STORE();
export function keepalive() {
  if (window.location.href.indexOf('/login') === -1) {
    // const now = new Date();
    // const hour = now.getHours();// 得到小时数
    // const minute = now.getMinutes();// 得到分钟数
    // console.log(`${hour}点${minute}分`);
    // console.log('keepalive');

    global.http
      .post(global.api.keeplive)
      .then((res) => {})
      .catch((err) => {
        console.error('keep alive error', err);
      });
  }
}
function postUnReadMessage(theFirstTime?: boolean) {
  if (window.location.href.indexOf('/login') === -1) {
    global.http
      .get(global.api.getUnReadMessage)
      .then((res: any) => {
        if (global.DATA.isNotNULL(res.data)) {
          if (global.DATA.isNotNULL(res.data.datas)) {
            Mobx.unReadMessage = res.data.datas;
            // console.log(res.data.datas)
          }
        }
      })
      .catch((err) => {
        console.error('postUnReadMessage', err);
      });
  }
}
export function polling() {
  keepalive();
  // postUnReadMessage(true);
  // setInterval(function () {
  //   postUnReadMessage();
  // }, 1000 * 5);
  setInterval(function () {
    keepalive();
  }, 1000 * 60 * 15);
}
