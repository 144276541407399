/**
 * 用于记录更新beta的版本号、更新日期以及更新内容
 */

/*
 * 合并测量记录
 *
 * */
const version = '3.0.0'; // 202010301817
export default version;
