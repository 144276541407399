/*
 * @Author: zhangzhipeng
 * @Date: 2021-02-04 13:37:17
 * @LastEditors: zhangzhipeng
 * @LastEditTime: 2022-09-26 09:51:34
 * @Description: 文件说明
 */
import global from '@/utils/global';
import type { LoginParamsType } from './type';

/**
 * 获取用户信息
 * @param
 */
export async function userInfo() {
  return global.http.post(global.api.userInfo, {});
}
/**
 * 获取菜单父
 */

export async function menuParent() {
  return global.http.post(global.api.menuParent);
}
/**
 * 获取菜单子
 */
export async function menuChildren() {
  return global.http.post(global.api.menuChildren);
}
/**
 * 登录
 * @param
 */
export async function login(params: LoginParamsType) {
  return global.http.post(global.api.login, {
    userNo: params.username,
    pwd: params.password,
    extraInfo: `browser:${navigator.userAgent},bversion:${
      navigator.userAgent.split('/')
        ? navigator.userAgent.split('/')[navigator.userAgent.split('/').length - 1]
        : 0
    },bwidth:${document.body.clientWidth},bheight:${document.body.clientHeight}`,
    channelVer: params.channelVer,
  });
}

/**
 * 获取key
 */
export async function getKey() {
  return global.http.get(global.api.getKey);
}

export default { getKey, login, menuParent, menuChildren, userInfo };
