export default {
  'app.settings.menuMap.basic': 'Pengaturan Dasar',
  'app.settings.menuMap.security': 'Pengaturan Keamanan',
  'app.settings.menuMap.binding': 'Pengikatan Akun',
  'app.settings.menuMap.notification': 'Notifikasi Pesan Baru',
  'app.settings.basic.avatar': 'Avatar',
  'app.settings.basic.change-avatar': 'Ubah avatar',
  'app.settings.basic.email': 'Email',
  'app.settings.basic.email-message': 'Tolong masukkan email!',
  'app.settings.basic.nickname': 'Nickname',
  'app.settings.basic.nickname-message': 'Tolong masukkan Nickname!',
  'app.settings.basic.profile': 'Profil Personal',
  'app.settings.basic.profile-message': 'Tolong masukkan profil personal!',
  'app.settings.basic.profile-placeholder': 'Perkenalan Singkat tentang Diri Anda',
  'app.settings.basic.country': 'Negara/Wilayah',
  'app.settings.basic.country-message': 'Tolong masukkan negara anda!',
  'app.settings.basic.geographic': 'Provinsi atau kota',
  'app.settings.basic.geographic-message': 'Tolong masukkan info geografis anda!',
  'app.settings.basic.address': 'Alamat Jalan',
  'app.settings.basic.address-message': 'Tolong masukkan Alamat Jalan anda!',
  'app.settings.basic.phone': 'Nomor Ponsel',
  'app.settings.basic.phone-message': 'Tolong masukkan Nomor Ponsel anda!',
  'app.settings.basic.update': 'Perbarui Informasi',
  'app.settings.security.strong': 'Kuat',
  'app.settings.security.medium': 'Sedang',
  'app.settings.security.weak': 'Lemah',
  'app.settings.security.password': 'Kata Sandi Akun',
  'app.settings.security.password-description': 'Kekuatan Kata Sandi saat ini',
  'app.settings.security.phone': 'Keamanan Ponsel',
  'app.settings.security.phone-description': 'Mengikat Ponsel',
  'app.settings.security.question': 'Pertanyaan Keamanan',
  'app.settings.security.question-description':
    'Pertanyaan Keamanan belum diatur, dan kebijakan keamanan dapat melindungi akun secara efektif',
  'app.settings.security.email': 'Email Cadangan',
  'app.settings.security.email-description': 'Mengikat Email',
  'app.settings.security.mfa': 'Perangka MFA',
  'app.settings.security.mfa-description':
    'Tidak mengikat Perangkat MFA, setelah diikat, dapat dikonfirmasi dua kali',
  'app.settings.security.modify': 'Modifikasi',
  'app.settings.security.set': 'Setel',
  'app.settings.security.bind': 'Ikat',
  'app.settings.binding.taobao': 'Mengikat Taobao',
  'app.settings.binding.taobao-description': 'Tidak mengikat akun Taobao saat ini',
  'app.settings.binding.alipay': 'Mengikat Alipay',
  'app.settings.binding.alipay-description': 'Tidak mengikat akun Alipay saat ini',
  'app.settings.binding.dingding': 'Mengikat DingTalk',
  'app.settings.binding.dingding-description': 'Tidak mengikat akun DingTalk',
  'app.settings.binding.bind': 'Ikat',
  'app.settings.notification.password': 'Kata Sandi Akun',
  'app.settings.notification.password-description':
    'Pesan dari pengguna lain akan diberitahu dalam bentuk surat',
  'app.settings.notification.messages': 'Pesan Sistem',
  'app.settings.notification.messages-description':
    'Pesan sistem akan diberitahu dalam bentuk surat',
  'app.settings.notification.todo': 'Notifikasi daftar To-do',
  'app.settings.notification.todo-description':
    'Daftar to-do akan diberitahukan dalam bentuk surat dari stasiun',
  'app.settings.open': 'Buka',
  'app.settings.close': 'Tutup',
};
