/*
 * @Author: zhangzhipeng
 * @Date: 2021-04-21 08:02:55
 * @最后修改人: zhangzhipeng
 * @最后修改时间: 2022-04-25 14:15:47
 * @Description: 更改原始方法
 */
export function changePrototype() {
  Array.prototype.indexOf = function (val) {
    for (let i = 0; i < this.length; i++) {
      if (this[i] === val) return i;
    }
    return -1;
  };
  // 删除数组中的元素
  // Array.prototype.remove = function (val: any) {
  //   const index = this.indexOf(val);
  //   if (index > -1) {
  //     this.splice(index, 1);
  //   }
  // };
  // 删除数组中的元素,返回修改后的
  // Array.prototype.remove_res = function (val: any) {
  //   const res = [];
  //   for (let i = 0; i < this.length; i++) {
  //     if (i !== val) {
  //       res.push(this[i]);
  //     }
  //   }
  //   return res;
  // };
  // Array.prototype.size = function (val: any) {
  //   try {
  //     const length = this.length;
  //     return length;
  //   } catch (error) {
  //     return 0;
  //   }
  // };
  window.div = (props: any) => props.children;

  // 重写console
  //    window.console=(function(origConsole){

  //     if(!window.console){
  //       return window.console
  //     }
  //     return {
  //       ...window.console,
  //         log: function(){
  //           origConsole.log(arguments)
  //           origConsole.log(origConsole)
  //             origConsole && origConsole.log && origConsole.log(arguments[0]);
  //         },
  //         info : function(){
  //             origConsole.info(arguments[0]);
  //         },
  //         warn : function(){
  //             origConsole.warn(arguments[0]);
  //         },
  //         error : function(){
  //             origConsole.error(arguments);
  //         },
  //         time : function(){
  //             origConsole.time(arguments[0]);
  //         },
  //         timeEnd : function(){
  //             origConsole.timeEnd(arguments[0]);
  //         },
  //     };
  // }(window.console));

  // window.onerror = function (message, source, lineno, colno, error) {
  //   //     message：错误消息（字符串）。在HTML οnerrοr=""处理程序event（sic！）中可用。
  //   // source：引发错误的脚本的URL（字符串）
  //   // lineno：发生错误的行号（数值）
  //   // colno：发生错误的行的列号（数值）
  //   // error：错误对象（对象）
  //   console.error(`┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`);
  //   console.error('文件URL:', source);
  //   console.error('错误的行号', lineno);
  //   console.error('错误的行的列号', colno);
  //   console.error('错误消息:', message);
  //   console.error('错误的明细', error?.stack);
  //   console.error(`┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`);
  // };

  window.addEventListener(
    'error',
    function (e) {
      e.preventDefault(); // 阻止异常向上抛出
      e.stopPropagation();

      console.error(`┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`);
      console.error('捕获到异常error:');
      console.error('文件URL', e?.filename);
      console.error('错误的行号', e.lineno);
      console.error('错误的行的列号', e.colno);
      console.error('错误消息:', e?.message);
      console.error(e.error);
      console.error(
        '原始信息:',
        JSON.stringify({
          data: e.error?.stack,
          time: new Date().getTime(),
          VERSION: process.env.VERSION,
        }),
      );
      console.error(`┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`);
    },
    { passive: false },
  );

  window.addEventListener(
    'unhandledrejection',
    function (e) {
      if (typeof e.reason == 'string') {
        return;
      }
      e.preventDefault(); // 阻止异常向上抛出
      e.stopPropagation();
      if (e?.reason?.message || e?.reason) {
        console.error(`┏━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`);
        console.error('捕获到异常unhandledrejection：');
        console.error('错误消息:', e?.reason?.message);
        console.error(e?.reason);
        console.error(e);
        console.error(
          '原始信息:',
          JSON.stringify({ data: e, time: new Date().getTime(), VERSION: process.env.VERSION }),
        );
        console.error(`┗━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━━`);
      }
    },
    { passive: false },
  );
  process.on('unhandledRejection', (reason, p) => {
    console.error('Unhandled Rejection at: Promise ', p, ' reason: ', reason);
    // application specific logging, throwing an error, or other logic here
  });
  process.on('uncaughtException', (e) => {
    console.error('我能进来，说明可以处理异常');
    console.error(e);
  });
}
